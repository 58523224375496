import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as me from "melonjs";
import onload from "./Game/GameInit";
import game from "./Game/game";
import Home from "./components/pages/Home/Home";
import Loading from "./components/pages/Loading/Loading";
import { Route, Routes } from "react-router-dom";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import classes from "./App.module.css";
import { decode, getToken, getUser } from "./api/userApi";
import { useDispatch, useSelector } from "react-redux";
import {
  setUser,
  updateEnergy,
  updateLeague,
  updateMiningSecodns,
  updateRegular,
  updateTimeToFeed,
} from "./store/userSlice";
import { useTapSocket } from "./api/tapApi";
import WebSocketClient from "./api/wsApi";
import GameHeader from "./components/ui/GameHeader/GameHeader";
import Earn from "./components/pages/Earn/Earn";
import Invite from "./components/pages/Invite/Invite";
import { setStoreToken } from "./store/tokenSlice";
import PlayScreen from "./Game/Screens/PlayScreen";
import { setClose, setContent, setOpen } from "./store/oopsSlice";
import Oops from "./components/modals/Oops/Oops";

const GameCanvas = styled.div`
  background-color: #000;
  width: 100%;
  height: 100vh;
  display: block;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

let initiated = false;
let isMoving = false;
let startX = 0;
let startY = 0;

// Event Handlers
function pointerUp() {
  console.log("Pointer Up registered"); // Debugging
  game.input.registerPointerRelease();
}

function handleTouchStart(e) {
  startX = e.touches[0].clientX;
  startY = e.touches[0].clientY;
  isMoving = false;
}

function handleTouchMove(e) {
  if (!isMoving) {
    const moveX = e.touches[0].clientX;
    const moveY = e.touches[0].clientY;

    const diffX = moveX - startX;
    const diffY = moveY - startY;

    if (Math.abs(diffX) > 5 || Math.abs(diffY) > 5) {
      e.preventDefault();
      isMoving = true;
      console.log("Swipe prevented");
    }
  } else {
    e.preventDefault();
  }
}

function handleTouchEnd() {
  isMoving = false;
}

// Functions to Prevent Unwanted Actions
function preventAllSwipes() {
  window.addEventListener("touchstart", handleTouchStart, { passive: false });
  window.addEventListener("touchmove", handleTouchMove, { passive: false });
  window.addEventListener("touchend", handleTouchEnd, { passive: false });
}

function preventDoubleTapZoom() {
  document.addEventListener(
    "dblclick",
    function (e) {
      e.preventDefault();
    },
    { passive: false }
  );

  document.addEventListener(
    "gesturestart",
    function (e) {
      e.preventDefault();
    },
    { passive: false }
  );
}

function deactivateSwipePrevention() {
  window.removeEventListener("touchstart", handleTouchStart, {
    passive: false,
  });
  window.removeEventListener("touchmove", handleTouchMove, { passive: false });
  window.removeEventListener("touchend", handleTouchEnd, { passive: false });
  document.removeEventListener("dblclick", preventDoubleTapZoom, {
    passive: false,
  });
  document.removeEventListener("gesturestart", preventDoubleTapZoom, {
    passive: false,
  });
}

// Main App Component
const App = () => {
  const WebApp = useWebApp();
  const [loading, setLoading] = useState(true);
  const [gameStarted, setGameStarted] = useState(false);
  const [token, setToken] = useState();
  const [activeTab, setActiveTab] = useState();
  const gameRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const oops = useSelector((state) => state.oops.isOpen);
  const oopsText = useSelector((state) => state.oops.content);

  useEffect(() => {
    if (user.mining_level) {
      const interval = setInterval(() => {
        if (user.mining_claim_seconds < 28800) {
          dispatch(
            updateMiningSecodns({
              mining_claim_seconds: user.mining_claim_seconds + 1,
            })
          );
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [user.mining_level, user.mining_claim_seconds, dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (user.time_since_last_feed < 21600) {
        dispatch(
          updateTimeToFeed({
            time_since_last_feed: user.time_since_last_feed + 1,
          })
        );
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [user.time_since_last_feed, dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (user.energy < 100) {
        dispatch(updateEnergy({ energy: user.energy + 1 }));
      }
    }, 60000 * (1 - 0.05 * user.energy_boost_level));

    return () => clearInterval(interval);
  }, [user.energy, user.energy_boost_level]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getToken();
      const token = response.access_token;
      setToken(token);
      dispatch(setStoreToken(token));
      await getUser(token).then((data) => dispatch(setUser(data)));
    };

    fetch();
  }, []);

  useEffect(() => {
    if (token) {
      WebSocketClient.init(
        `wss://tap.hare-online.com/ws/${decode(token)}/${token}`
      ); // Initialize WebSocket client

      WebSocketClient.setOnMessageCallback((message) => {
        console.log("Message received in React component:", message);
        if (message.startsWith("regular_carrots")) {
          const [carrotsText, leagueText] = message.split(", ");
          const newCarrots = parseFloat(carrotsText.split(": ")[1]);
          const currentLeague = parseInt(leagueText.split(": ")[1]);

          dispatch(updateRegular({ regular_carrots: newCarrots }));
          dispatch(updateLeague({ current_league: currentLeague }));
        }
        if (message.startsWith("Игра начата")) {
          const energyText = message.split("Остаток энергии: ")[1];
          const energy = parseInt(energyText, 10);
          dispatch(updateEnergy({ energy: energy }));
        }
        if (message.startsWith("Недостаточно")) {
          dispatch(setOpen());
          dispatch(setContent("Not enough energy"));
        }
      });

      return () => {
        WebSocketClient.close(); // Cleanup on component unmount
      };
    }
  }, [token]);

  useEffect(() => {
    if (!initiated) {
      initiated = true;

      me.device.onReady(() => {
        onload(exitGame);
      });

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    if (gameStarted) {
      activateSwipePrevention();
      game.gameController.start(gameRef.current);
    } else {
      deactivateSwipePrevention();
    }
  }, [gameStarted]);

  const startGame = () => {
    WebSocketClient.sendMessage("game start");
    setGameStarted(true);
  };

  const exitGame = () => {
    WebSocketClient.sendMessage("game stop");
    setGameStarted(false);
    navigate("/");
    game.gameController.exit();
  };

  function activateSwipePrevention() {
    preventAllSwipes();
    preventDoubleTapZoom();
  }

  useEffect(() => {
    setActiveTab(
      <Home
        startGame={startGame}
        started={initiated}
        setActiveTab={setActiveTab}
      />
    );
  }, []);

  return (
    <div className={classes.App} style={{ position: "relative" }}>
      {!gameStarted ? (
        <Routes>
          <Route path="/" element={activeTab} />
        </Routes>
      ) : (
        <>
          <GameHeader />
          {oops && (
            <Oops
              isOpen={oops}
              onClose={() => {
                dispatch(setClose());
                exitGame();
              }}
              text={oopsText}
            />
          )}
          <GameCanvas ref={gameRef} id="screen" onPointerUp={pointerUp} />
        </>
      )}

      {loading && (
        <LoadingOverlay>
          <Loading />
        </LoadingOverlay>
      )}
    </div>
  );
};

export default App;
