function getRandomElementWithWeights(array, weights) {
    if (array.length !== weights.length) {
      throw new Error("array lengths do not match in getRandomElementWithWeights");
    }
  
    const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);
    const randomValue = Math.random() * totalWeight;
  
    let accumulatedWeight = 0;
    for (let i = 0; i < array.length; i++) {
      accumulatedWeight += weights[i];
      if (randomValue < accumulatedWeight) {
        return array[i];
      }
    }
  
    console.log("something wrong with weights... returning last element. " + array + ". " + weights + ". " + totalWeight);
    return array[array.length - 1]; 
  }

  export default getRandomElementWithWeights;