import classes from './NoPhone.module.css'

const NoPhone = () => {
  return (
    <div className={classes.NoPhone}>
      <img src={'/assets/noPhoneBg.svg'} alt='bg' className={classes.bg}/>
      <div className={classes.buttons}>
        <div className={classes.buttonsTop}>
          <div className={classes.telegram}>
            Telegram
          </div>
          <div className={classes.twitter}>
            X (Twitter)
          </div>
        </div>
        <div className={classes.buttonsBot}>
          <div className={classes.discord}>
            Discord
          </div>
          <div className={classes.website}>
            Website
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoPhone;