import * as me from 'melonjs';
import GenerationUnits from './GenerationUnits';
import ObjectGenerator from './ObjectGenerator';
import PlatformObjectGenerator from './PlatformObjectGenerator';
import ProdGenerationConfig from '../../components/configs/GenerationConfig';
import * as config from '../../components/configs/GeneratorConfigClasses';
import ObjectPlacer from './ObjectPlacer';
import Camera from '../Entities/Camera';
import PlatformPlacer from './PlatformPlacer';
import PlatformEntity from '../Entities/Level/Platform';

class LevelGenerator extends me.Entity {
    /** @type {LevelGenerator} */
    static current;

    /** @argument {GenerationUnits} */
    constructor(generationUnits = GenerationUnits) {
        super(0, 0, { width: 1, height: 1 });
        LevelGenerator.current = this;

        this.units = generationUnits;
        this.units.calculate();
        this.units.setPlatformNumber(0);
        this.units.setY(0);

        this.alwaysUpdate = true;
        this.lastSavedY = 0;

        this.objectPlacer = new ObjectPlacer();
        this.generationSetController = new GenerationSetController(new ProdGenerationConfig(), this.objectPlacer);

        PlatformPlacer.clear();
        PlatformEntity.clearPlatforms();

        this.generateBeginning();

        this.frameCount = 0; // счетчик кадров для ограниченного обновления
        this.initialUpdateDone = false; // флаг для начального обновления
    }

    generateBeginning() {
        const screenPosition = GenerationUnits.getWorldPixelPositionOf('screen', 0);
        this.objectPlacer.placeRelativelyToScreenWidth('platform', 0.2, screenPosition);
        this.objectPlacer.placeRelativelyToScreenWidth('platform', 0.5, screenPosition);
        this.objectPlacer.placeRelativelyToScreenWidth('platform', 0.8, screenPosition);

        this.objectPlacer.placeRelativelyToScreenWidth('player', 0.5, GenerationUnits.getWorldPixelPositionOf('screen', 0.1));

        this.newHeightReached(screenPosition);
    }

    update(dt) {
        if (!this.initialUpdateDone) {
            // Первоначальное обновление сразу после старта или перезапуска игры
            const nextY = this.getGenerateY();
            this.newHeightReached(nextY);
            this.initialUpdateDone = true;
            return true;
        }

        this.frameCount++; // увеличиваем счетчик кадров

        if (this.frameCount >= 120) { // обновляем не чаще 120 кадров
            this.frameCount = 0; // сбрасываем счетчик после обновления

            const nextY = this.getGenerateY();
            const remainingObjectsAbove = this.countObjectsAboveScreen(nextY);

            // Если объектов меньше или равно 1, запускаем генерацию нового уровня
            if (remainingObjectsAbove <= 1 && nextY > this.lastSavedY) {
                this.newHeightReached(nextY);
            }
        }

        return true;
    }

    getGenerateY() {
        return -me.game.viewport.getBounds().top + me.game.viewport.height / 2;
    }

    newHeightReached(y) {
        if (this.units.getY() === y) return;  // Пропуск генерации, если позиция не изменилась
        this.units.setY(y);
        this.generationSetController.floatingGenerate();
    }

    countObjectsAboveScreen(y) {
        // Проверяем, существует ли массив платформ и является ли он массивом
        if (!Array.isArray(PlatformEntity.platforms)) {
            return 0; // Если массив не определен, возвращаем 0
        }
    
        let objectsAbove = 0;
    
        // Подсчитываем количество платформ выше текущей высоты y
        PlatformEntity.platforms.forEach(platform => {
            if (platform.pos.y < y) {
                objectsAbove++;
            }
        });
    
        return objectsAbove;
    }
    

    newPlatformGeneratedCallback(platform) {
        GenerationUnits.setPlatformNumber(GenerationUnits.getPlatformNumber() + 1);
        platform.index = GenerationUnits.getPlatformNumber();

        this.generationSetController.onPlatformGenerate();
    }
}

class GenerationSetController {
    /** @argument {GenerationConfig} config */
    constructor(config, objectPlacer) {
        this.config = config;
        this.objectPlacer = objectPlacer;
        this.generationSet = null;
        this.generationSetConfig = null;

        this.updateCurrentSetConfig();
    }

    updateCurrentSetConfig() {
        const config = this.getCurrentSetConfig();
        if (config !== this.generationSetConfig) {
            this.generationSetConfig = config;
            this.generationSet = new GenerationSet(config, this.objectPlacer);
        }
    }

    getCurrentSetConfig() {
        return this.config.getCurrentLevel();
    }

    floatingGenerate() {
        if (this.generationSet) {
            this.generationSet.floatingGenerate();
        }
    }

    onPlatformGenerate() {
        if (this.generationSet) {
            this.generationSet.onPlatformGenerate();
        }
    }
}

class GenerationSet {
    /** @argument {config.GenerationSet} setConfig */
    constructor(setConfig, objectPlacer) {
        if (typeof setConfig !== 'object') {
            throw new Error('setConfig not an object');
        }
        if (typeof objectPlacer !== 'object') {
            throw new Error('objectPlacer not an object');
        }

        this.setConfig = setConfig;
        this.objectPlacer = objectPlacer;

        this.floatingGenerators = [];
        this.onPlatformGenerators = [];

        this.parseSet();
    }

    parseSet() {
        for (const generatorConfig of this.setConfig.floatingObjects.list) {
            this.floatingGenerators.push(new ObjectGenerator(generatorConfig, this.objectPlacer));
        }
        for (const generationConfig of this.setConfig.platformObjects.list) {
            this.onPlatformGenerators.push(new PlatformObjectGenerator(generationConfig));
        }
    }

    floatingGenerate() {
        for (const generator of this.floatingGenerators) {
            generator.generate();
        }
    }

    onPlatformGenerate() {
        for (const generator of this.onPlatformGenerators) {
            generator.generate();
        }
    }
}

export default LevelGenerator;
