import classes from './Menu.module.css'
import bg from '../../../assets/images/menu-bg.svg'
import MenuItem from '../MenuItem/MenuItem';
import play from '../../../assets/images/m-play.png'
import boost from '../../../assets/images/m-boost.png'
import earn from '../../../assets/images/m-earn.png'
import invite from '../../../assets/images/m-invite.png'
import oCarrotButton from '../../../assets/images/m-carrotbutton.png'
import gCarrotButton from '../../../assets/images/goldCarrotButton.png'
import dCarrotButton from '../../../assets/images/diamondCarrotButton.png'
import carrotSwitch from '../../../assets/images/m-switchCarrot.png'
import Earn from '../../pages/Earn/Earn';
import Invite from '../../pages/Invite/Invite';
import Home from '../../pages/Home/Home';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setContent, setOpen } from '../../../store/oopsSlice';
import { feed } from '../../../api/feedRabbitApi';
import { updateBalance, updateRegular, updateTimeToFeed } from '../../../store/userSlice';
import carrot from '../../../assets/images/orangeValue.svg'
import Oops from '../../modals/Oops/Oops';

const Menu = ({ startGame, started, setActiveTab, boostOpen}) => {
  const [currentCarrot, setCurrentCarrot] = useState(oCarrotButton)
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token.token)
  const step = useSelector((state) => state.step.step);

  const dispatch = useDispatch()


  const handleNextCarrot = () => {
    if (currentCarrot === oCarrotButton) {
      setCurrentCarrot(gCarrotButton)
    }
    if (currentCarrot === gCarrotButton) {
      setCurrentCarrot(dCarrotButton)
    }
    if (currentCarrot === dCarrotButton) {
      setCurrentCarrot(oCarrotButton)
    } 
  }

  const handlePrevCarrot = () => {
    if (currentCarrot === oCarrotButton) {
      setCurrentCarrot(dCarrotButton)
    }
    if (currentCarrot === gCarrotButton) {
      setCurrentCarrot(oCarrotButton)
    }
    if (currentCarrot === dCarrotButton) {
      setCurrentCarrot(gCarrotButton)
    } 
  }

  const startGameHandler = () => {
    if (user.energy >= 5) {
      startGame()
    } else {
      dispatch(setContent('Not enough energy'))
      dispatch(setOpen())
    }
  }

  const feedRabbit = async () => {
    try {
      const response = await feed(token)
      dispatch(updateBalance({ balance: response.balance }))
      dispatch(updateRegular({ regular_carrots: response.regular_carrots }))
      dispatch(updateTimeToFeed({ time_since_last_feed: response.time_since_last_feed }))
    } catch (error) {
      if (user.time_since_last_feed < 28800) {
        dispatch(setOpen())
        dispatch(setContent(<p>Feeding is possible only after 6 hours</p>))
      } else {
        dispatch(setOpen())
        dispatch(setContent(<p>Need 100 <img src={carrot} alt='carrot' className={classes.oopsCarrot}/>to feed</p>))
      }
    }
  }

  return (
    <div className={classes.Menu}>
      <img src={bg} alt='bg' className={classes.bg}/>
      <div className={classes.carrotContainer}>
        <div className={classes.left}>
          <img src={carrotSwitch} alt='switch' style={{zIndex: step === 2 && '10000'}} className={classes.switchL} onClick={handlePrevCarrot}/>
        </div>
        <div className={classes.carrot}>
          <img src={currentCarrot} alt='button' style={{zIndex: (step === 1 && user.is_new_user) && '10000'}} className={classes.carrotButton} onClick={feedRabbit}/>
        </div>
        <div className={classes.right}>
          <img src={carrotSwitch} alt='switch' style={{zIndex: step === 2 && '10000'}} className={classes.switchR} onClick={handleNextCarrot}/>
        </div>
      </div>
      <div className={classes.MenuItems}>
        <div className={classes.part}>
          <MenuItem title={'GAME'} onClick={startGameHandler}/>
          <MenuItem 
            title={'EARN'} 
            onClick={() => setActiveTab(
              <Earn 
                startGame={startGame} 
                started={started}
                onClose={() => setActiveTab(<Home startGame={startGame} started={started} setActiveTab={setActiveTab} />)}/>)}
              />
        </div>
        <div className={classes.part}>
          <MenuItem title={'BOOST'} onClick={boostOpen}/>
          <MenuItem title={'INVITE'} onClick={() => setActiveTab(<Invite startGame={startGame} started={started} onClose={() => setActiveTab(<Home startGame={startGame} started={started} setActiveTab={setActiveTab} />)}/>)}/>
        </div>
      </div>
    </div>
  )
}

export default Menu;