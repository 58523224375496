import * as me from 'melonjs'
import entityParameters from '../../../components/configs/entityParameters.json'
import EntityScaler from '../Tools/EntityScaler';
import WorldEntity from './WorldEntity';
import LevelGenerator from '../../LevelGenerator/LevelGenerator';

const platforms = [];

class PlatformEntity extends WorldEntity {

    constructor(x, y, platformType) {
        var image = me.loader.getImage('platform');

        super(x, y , {
            image: "platform",
            width: image.width,
            height: image.height,
        });

        this.z = -10000;
        this.type = "platform";
        this.platformType = platformType;
        this.config = entityParameters["platform_" + this.platformType];
        this.index = 0;

        if (typeof(this.config) == 'undefined') {
            throw new Error('cannot find config for platform type ' + this.platformType);
        }

        EntityScaler.scaleToFitWidth(this, this.config.width);

        this.body.collisionType = me.collision.types.WORLD_SHAPE;
        this.anchorPoint.set(0.5, 0.5);
        this.body.setStatic(true);

        this.updatePosInBounds();
    }

    remove() {
        super.remove();
        this.unregister();
    }

    static clearPlatforms() {
        platforms.splice(0, platforms.length);
    }
    /** @returns {PlatformEntity} */
    static getPlatformUnderIndex(index) {
        for(var platform of platforms) {
            if (platform.index == index) {
                return platform;
            }
        }
    }
    register() {
        platforms.push(this);
        LevelGenerator.current.newPlatformGeneratedCallback(this);
    }
    unregister() {
        const index = platforms.indexOf(this);
        if (index > -1) {
            platforms.splice(index, 1);
        }
        return platforms;
    }
}

export default PlatformEntity;