import GenerationUnits from "../../Game/LevelGenerator/GenerationUnits";
import getRandomElementWithWeights from "../../Game/Utils/getRandomElementWithWeights";

class GenerationConfig {
    /**
     * @type {GenerationSet[]}
     */
    levels = [];
    defaultPlatform = '';
    leftLimitX = 0.1;
    rightLimitX = 0.9;
    
    /** @type {GenerationConfig} */
    static instance;

    constructor() {
        GenerationConfig.instance = this;
    }

    addLevel(level) {
        this.levels.push(level);
    }

    /** @returns {GenerationSet} */
    getCurrentLevel() {
        return this.getLevelFor(GenerationUnits.getPlatformNumber());
    }

    /**
     * 
     * @param {number} platformIndex 
     * @returns {GenerationSet}
     */
    getLevelFor(platformIndex) {
        var currentIndex = 0;
        for(var i in this.levels) {
            var length = this.levels[i].length;
            currentIndex += length;

            if (platformIndex <= currentIndex) {
                return this.levels[i];
            }
        }
        return this.levels[this.levels.length - 1];
    }
}

class GenerationSet {
    floatingObjects = new GeneratedObjects();
    platformObjects = new GeneratedObjects();
    length = 100;

    /** @returns {ObjectGenerationConfig} */
    addPlatformGenerator(jumpDistanceMin, jumpDistanceMax, variants=undefined) {
        var config = new ObjectGenerationConfig('platform', jumpDistanceMin, jumpDistanceMax, 'jump', variants);
        this.floatingObjects.add(config);
        return config;
    }
    /** @returns {ObjectGenerationConfig} */
    addFloatingObjectGenerator(entityIndex, distanceMin, distanceMax, unit) {
        var config = new ObjectGenerationConfig(entityIndex, distanceMin, distanceMax, unit);
        this.floatingObjects.add(config);
        return config;
    }
    /** @returns {ObjectGenerationConfig} */
    addOnPlatformObjectGenerator(entityIndex, distanceMin, distanceMax) {
        var config = new ObjectGenerationConfig(entityIndex, distanceMin, distanceMax, 'platform');
        this.platformObjects.add(config);
        return config;
    }
}

class GeneratedObjects {
    constructor() {
        this.list = [];
    }

    add(definition) {
        this.list.push(definition);
    }
}
class ObjectGenerationConfig {

    /** @argument {('pixel'|'platform'|'screen'|'jump')} unit */
    constructor(index, distanceMin, distanceMax, unit='jump', variants=undefined) {
        this.index = index;
        this.distanceMin = distanceMin;
        this.distanceMax = distanceMax;
        this.count = 1;
        this.unit = unit;
        this.setVariants(variants);

        this.lastPosition = 0;
    }

    setVariants(variants) {
        if (Array.isArray(variants)) {
            if (variants.length == 2 && Array.isArray(variants[0])) {
                this.variants = new ObjectGenerationWeightedVariants(variants[0], variants[1]);
            } else {
                this.variants = new ObjectGenerationRandomVariants(variants);
            }
        } else if (typeof(variants) == 'object') {
            this.variants = variants;
        } else {
            this.variants = new ObjectGenerationVariant(variants);
        }
    }

    /** @returns {ObjectGenerationConfig} */
    withVariants(variants) {
        this.setVariants(variants);
        return this;
    }
    /** @returns {ObjectGenerationConfig} */
    withCount(count) {
        this.count = count;
        return this;
    }
    withUnit(unit) {
        this.unit = unit;
    }

    getNextVariant() {
        return this.variants.getNext();
    }
}
class ObjectGenerationVariant {
    constructor(variant) {
        this.variant = variant;
    }
    getNext() {
        return this.variant;
    }
}
class ObjectGenerationWeightedVariants extends ObjectGenerationVariant {
    constructor(list, weights) {
        super(undefined);

        this.list = list;
        this.weights = weights;
    }

    getNext() {
        return getRandomElementWithWeights(this.list, this.weights);
    }
}
class ObjectGenerationRandomVariants extends ObjectGenerationWeightedVariants {
    constructor(list) {
        var weights = [];
        for(;weights.length < list.length;) {
            weights.push(1);
        }

        super(list, weights);
    }
}

export {
    GenerationConfig,
    GenerationSet,
    GeneratedObjects,
    ObjectGenerationConfig,
    ObjectGenerationVariant,
    ObjectGenerationRandomVariants,
    ObjectGenerationWeightedVariants
}