import EntityScaler from "../Entities/Tools/EntityScaler";
import entityParameters from "../../components/configs/entityParameters.json";
import PlatformEntity from "../Entities/Level/Platform";

class GenerationUnits {
    constructor() {
        this.jumpLength = 0;
        this.screenHeight = 0;

        this.y = 0;
        this.platformNumber = 0;
    }

    calculate() {
        this.jumpLength = EntityScaler.scaleToHeight(entityParameters.player.jump_height);
        this.screenHeight = EntityScaler.scaleToHeight(100);
    }

    /** @param {('pixel'|'platform'|'screen'|'jump')} unit  */
    getWorldPixelPositionOf(unit, value) {
        return -this.getPixelPositionOf(unit, value);
    }
    getPixelPositionOf(unit, value) {
        if (unit == 'platform') {
            return this.getY();
        }

        if (unit == 'jump') {
            return this.jumpToPixels(value);
        }
        if (unit == 'pixel') {
            return value;
        }
        if (unit == 'screen') {
            return value * this.screenHeight;
        }
    }

    /** @param {('pixel'|'platform'|'screen'|'jump')} unit  */
    getPosition(unit) {
        switch (unit) {
            case 'jump': return this.getJump();
            case 'platform': return this.getPlatformNumber();
            case 'pixel': return this.getY();
            case 'screen': return this.getScreen();
        }
    }

    getJump() {
        return this.pixelsToJump(this.getY());
    }
    getScreen() {
        return this.getY() / this.screenHeight;
    }
    getY() {
        return this.y;
    }
    setY(y) {
        this.y = y;
    }
    getPlatformNumber() {
        return this.platformNumber;
    }
    setPlatformNumber(number) {
        this.platformNumber = number;
    }
    
    pixelsToJump(pixels) {
        return pixels / this.jumpLength;
    }
    jumpToPixels(jump) {
        return jump * this.jumpLength;
    }
    percentToPixels(percent) {
        return percent/100 * this.screenHeight;
    }
}

export default new GenerationUnits();