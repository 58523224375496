import { useDispatch, useSelector } from 'react-redux';
import classes from './Invite.module.css'
import taskBg from '../../../assets/images/taskbg.png'
import inviteBg from '../../../assets/images/inviteImg.png'
import copyImg from '../../../assets/images/copyImg.png'
import rabbitIcon from '../../../assets/images/rabbitIcon.png'
import carrot from '../../../assets/images/orangeValue.svg'
import Home from '../Home/Home';
import close from '../../../assets/images/closeButton.png'
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getLink, getReferals } from '../../../api/friendsApi';
import { setLink } from '../../../store/linkSlice';
import { setReferrals } from '../../../store/friendsSlice';

const Invite = ({ onClose }) => {
  const token = useSelector((state) => state.token.token)
  const dispatch = useDispatch();
  const { loadedReferrals, totalFriends, premiumFriends } = useSelector((state) => state.friends);
  const link = useSelector((state) => state.link.link);

  const [copyMessage, setCopyMessage] = useState(''); // Состояние для сообщения о копировании

  const formatNumberToM = (num) => {
    if (num < 1000000) return num;
    return (num / 1000000).toFixed(2).replace(/\.00$/, '') + 'M';
  };

  const fetchLink = useCallback(async () => {
    console.log(link)
    if (link === '') {
      const response = await getLink(token);
      const invite_link = response.invite_link;
      dispatch(setLink(invite_link));
    }
  }, [token, link]);

  const fetchUsers = useCallback(async () => {
    if (loadedReferrals === 0) {
      const response = await getReferals(token);
      const users = response.referrals;
      console.log(users);
      if (users) {
        const sortedUsers = users.sort((a, b) => {
          return b.balance - a.balance;
        });
        dispatch(setReferrals(sortedUsers));
      }
    }
  }, [token, loadedReferrals, dispatch]);

  useEffect(() => {
    fetchLink();
  }, [fetchLink]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    setCopyMessage('Copied to clipboard!'); // Установить сообщение
    setTimeout(() => {
      setCopyMessage(''); // Очистить сообщение через 2 секунды
    }, 2000);
  };

  const sendLink = () => {
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    const text = "\nNeed text to send";
    tg.openTelegramLink(`https://t.me/share/url?url=${link}&text=${encodeURI(text)}`);
  };

  function removeHttpsPrefix(url) {
    const prefix = "https://";
    if (url.startsWith(prefix)) {
      return url.slice(prefix.length);
    }
    return url;
  }

  const getEarnings = useMemo(() => {
    let earnings = 0
    if (loadedReferrals[0]) {
      loadedReferrals.map((ref) => {
        earnings += ref.earnings
      })
    }
    return earnings
  }, [loadedReferrals]);

  const getSubEarnings = useMemo(() => {
    let subearnings = 0
    if (loadedReferrals[0]) {
      loadedReferrals.map((ref) => {
        subearnings += ref.subref_earnings
      })
    }
    return subearnings
  }, [loadedReferrals]);

  return (
    <div className={classes.Invite}>
      <div className={classes.cross} onClick={() => onClose()}>
        <img src={close} alt='close' className={classes.close}/>
      </div>
      {copyMessage && <div className={classes.copyMessage}>{copyMessage}</div>}
      <img src={'/assets/inviteImg.png'} alt='bg' className={classes.earnBg}/>
      <div className={classes.tasksContainer}>
        <img src={taskBg} alt='bg' className={classes.taskBg}/>
        <div className={classes.inviteContainer}>
          <div className={classes.text}>
            <p className={classes.mainText}>GET A FIXED BONUS OF UP TO<br/><span className={classes.color}>10000 HARE</span> YOU AND YOUR FRIEND!</p>
            <p className={classes.subText}>Refshare is already in Hare for refs 10% and<br/>subrefs 2% of accrued <span className={classes.color}>HARE</span> balance.</p>
            <div className={classes.inviteSection}>
              <div className={classes.inviteButton} onClick={sendLink}>
                Invite Friend
              </div>
              <div className={classes.inviteLink} onClick={copyLink}>
                <p className={classes.link}>{removeHttpsPrefix(link)}</p>
                <img src={copyImg} alt='copy' className={classes.copyImg}/>
              </div>
            </div>
            <div className={classes.block}>
              <div className={classes.stat}>
                <div className={classes.statInfo}>
                  <p className={classes.statTitle}>Premium</p>
                  <div className={classes.premiumStat}>
                    <p><span><img src={rabbitIcon} alt='icon' className={classes.icon}/></span>10.000 Hare</p>
                  </div>
                </div>
                <div className={classes.statInfo}>
                  <p className={classes.statTitle}>Normal</p>
                  <div className={classes.normalStat}>
                    <p><span><img src={rabbitIcon} alt='icon' className={classes.icon}/></span>1000 Hare</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.hr}/>
          <div className={classes.info}>
            <div className={classes.blocka}>
              <p className={classes.infoText}>FRIENDS<br/>INVITED</p>
              <div className={classes.subBlock}>
                <div className={classes.premiumStata}>
                  <p>{premiumFriends}<br/>Premium</p>
                </div>
                <div className={classes.normalStata}>
                  <p>{totalFriends - premiumFriends}<br/>Normal</p>
                </div>
              </div>
            </div>
            <div className={classes.blocka}>
              <p className={classes.infoText}>FRIEND<br/>BONUS</p>
              <div className={classes.subBlock}>
                <div className={classes.normalStata}>
                  <p>{formatNumberToM(getEarnings)} HARE</p>
                </div>
              </div>
            </div>
            <div className={classes.blocka}>
              <p className={classes.infoText}>BONUS FOR<br/>FRIENDS OF<br/>FRIENDS</p>
              <div className={classes.subBlock}>
                <div className={classes.normalStata}>
                  <p>{formatNumberToM(getSubEarnings)} HARE</p>
                </div>
              </div>
            </div>
            <div className={classes.blocka}>
              <p className={classes.infoText}>TOTAL<br/>ACCURED</p>
              <div className={classes.subBlock}>
                <div className={classes.normalStata}>
                  <p>{formatNumberToM(getEarnings + getSubEarnings)} HARE</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Invite;