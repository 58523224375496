import classes from './Onboarding.module.css'
import bg from '../../../assets/images/boostBg.svg'
import carrot from '../../../assets/images/orangeValue.svg'
import bdiamond from '../../../assets/images/b-diamond.png'
import bstamina from '../../../assets/images/b-stamina.png'
import bmeal from '../../../assets/images/b-meal.png'
import bmining from '../../../assets/images/b-mining.png'
import close from '../../../assets/images/closeButton.png'
import { updateBalance, updateEnergy, updateEnergyBoostLevel, updateMiningLevel, updateMiningSecodns, updateNewUser, updateRegular, updateTapBoostLevel } from '../../../store/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { claim, upgradeEnergy, upgradeMining, upgradeMultitap } from '../../../api/boosterApi'
import { setContent, setOpen } from '../../../store/oopsSlice'
import { useState } from 'react'
import triangle from '../../../assets/images/triangle.svg'
import { setStep } from '../../../store/onboardingSlice'

const Onboarding = ({ isOpen, onClose, soonOpen }) => {
  const dispatch = useDispatch()
  const [currentStep, setCurrentStep] = useState(1)
  const step = useSelector((state) => state.step.step);

  const content = [
    'Feed rabbit by 100 Carrots and more earn 1 hare token',
    'Choose the type of Carrot',
    'Get carrots in game',
    'Hunger scale',
    'Your hare balance',
    'Stamina scale',
  ]

  const position = [
    {bottom: '160px', left: '133px', rotate: '0', bbottom: '-9px'},
    {bottom: '150px', left: '133px', rotate: '0', bbottom: '-9px'},
    {bottom: '120px', left: '0px', rotate: '25deg', bbottom: '-7px'},
    {bottom: '300px', left: '133px', rotate: '0', bbottom: '-9px'},
    {top: '170px', left: '133px', rotate: '180deg', bbottom: '125px'},
    {bottom: '200px', left: '133px', rotate: '0', bbottom: '-9px'},
  ]
  
  const nextStep = () => {
    if (currentStep != 6) {
      setCurrentStep((prev) => prev + 1)
      dispatch(setStep(step + 1))
    }
    else {
      dispatch(updateNewUser({ is_new_user: false }))
      dispatch(setStep(0))
      onClose()
    }
  }

  if (!isOpen) return null;

  return (
    <div className={classes.overlay} onClick={onClose}>
      <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
        <div className={classes.Onboarding} style={{bottom: position[currentStep - 1].bottom, top: position[currentStep - 1].top}}>
          <div className={classes.triangle}><img src={triangle} alt='tri' className={classes.triangleIcon} style={{bottom: position[currentStep - 1].bbottom, left: position[currentStep - 1].left, transform: `rotate(${position[currentStep - 1].rotate})`}}/></div>
          <div className={classes.title}>
            Onboarding
          </div>
          <div className={classes.hr}></div>
          <div className={classes.content}>
            {content[currentStep-1]}
          </div>
          <div className={classes.footer}>
            <div className={classes.progress}>
              {currentStep}/6
            </div>
            <div className={classes.button} onClick={nextStep}>
              Next
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;