import * as me from 'melonjs'; 
import WorldEntity from '../WorldEntity';

const sectorWidth = 100;  // Ширина сектора, можно настроить под размер вашей игры
const sectorHeight = 100; // Высота сектора, можно настроить под размер вашей игры

// Массив для хранения объектов по секторам
const sectors = [];

// Функция для инициализации сетки секторов
function initializeSectors(worldWidth, worldHeight) {
    const numSectorsX = Math.ceil(worldWidth / sectorWidth);
    const numSectorsY = Math.ceil(worldHeight / sectorHeight);
    
    for (let x = 0; x < numSectorsX; x++) {
        sectors[x] = [];
        for (let y = 0; y < numSectorsY; y++) {
            sectors[x][y] = [];
        }
    }
}

class CollectableEntity extends WorldEntity {

    constructor(x, y, variant) {
        super(x, y, variant);
        this.checkCollisionTimer = 0.02;
    }

    update(dt) {
        super.update(dt);
        if (this.checkCollisionTimer > 0) {
            this.checkCollisionTimer -= dt * 0.001;
            this.resolveCollision();
        }
    }

    resolveCollision() {
        this.updatePosInBounds();
        
        const sectorX = Math.floor(this.pos.x / sectorWidth);
        const sectorY = Math.floor(this.pos.y / sectorHeight);
        
        if (sectors[sectorX] && sectors[sectorX][sectorY]) { // Проверка существования сектора
            sectors[sectorX][sectorY].forEach(entity => {
                if (entity !== this && this.isIntersectingWith(entity)) {
                    this.pos.y -= this.height;
                }
            });
        }
    }

    isIntersectingWith(entity) {
        return (
            this.pos.x > entity.pos.x - entity.width / 2 - this.width / 2 &&
            this.pos.x < entity.pos.x + entity.width / 2 + this.width / 2 &&
            this.pos.y > entity.pos.y - entity.height / 2 - this.height / 2 && 
            this.pos.y < entity.pos.y + entity.height / 2 + this.height / 2
        );
    }
}

export default CollectableEntity;
