import classes from './ComingSoon.module.css'
import bg from '../../../assets/images/boostBg.svg'
import carrot from '../../../assets/images/orangeValue.svg'
import bdiamond from '../../../assets/images/b-diamond.png'
import bstamina from '../../../assets/images/b-stamina.png'
import bmeal from '../../../assets/images/b-meal.png'
import bmining from '../../../assets/images/b-mining.png'
import close from '../../../assets/images/closeButton.png'


const ComingSoon = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className={classes.overlay} onClick={onClose}>
      <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
        <div className={classes.cross} onClick={() => onClose()}>
          <img src={close} alt='close' className={classes.close}/>
        </div>
        <img src={'/assets/soon.svg'} alt='bg' className={classes.bg}/>
        <div className={classes.content}>
          <p className={classes.text}>Coming<br/>Soon!<span className={classes.fontBack}>Coming<br/>Soon!</span></p>
          <div className={classes.button} onClick={() => onClose()}>
            Back
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;