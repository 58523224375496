import classes from './MenuItem.module.css'
import bg from '../../../assets/images/menu-bg.svg'
import play from '../../../assets/images/m-play.png'
import boost from '../../../assets/images/m-boost.png'
import earn from '../../../assets/images/m-earn.png'
import invite from '../../../assets/images/m-invite.png'
import { useSelector } from 'react-redux'
const MenuItem = ({ title, onClick }) => {
  const step = useSelector((state) => state.step.step);

  return (
    <div className={classes.MenuItem} style={{zIndex: (title === 'GAME' && step === 3) && '10000'}} onClick={onClick}>
      <div className={classes.menuButton}>
        <div className={classes.shadow}></div>
        {title === 'GAME' && <img src={play} alt='button' className={classes.playImg}/>}
        {title === 'EARN' && <img src={earn} alt='button' className={classes.earnImg}/>}
        {title === 'BOOST' && <img src={boost} alt='button' className={classes.boostImg}/>}
        {title === 'INVITE' && <img src={invite} alt='button' className={classes.inviteImg}/>}
      </div>
      <p className={classes.menuTitle}>{title}</p>
    </div>
  )
}

export default MenuItem;