import { useEffect, useRef, useState } from 'react';
import classes from './Loading.module.css'

const Loading = () => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0); // Для анимации прогресса
  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(false);
  const interval = useRef(null)
  
  useEffect(() => {
    const loadUserData = async () => {
      try {
        let loadProgress = 0;
        interval.current = setInterval(() => {
          loadProgress += 10;
          if (loadProgress >= 100) {
            clearInterval(interval?.current);
          }
          setProgress(loadProgress);
        }, 700);


        clearInterval(interval?.current);
        setProgress(100);
        setLoading(false);
      } catch (error) {
        console.error("Ошибка загрузки данных:", error);
      }
    };

    // Минимальная задержка отображения экрана загрузки (2 секунды)
    const minimumDisplayTime = setTimeout(() => {
      setMinimumTimeElapsed(true);
    }, 5000);

    loadUserData();

    return () => {
      clearTimeout(minimumDisplayTime);
    };
  }, []);

  if (loading || !minimumTimeElapsed) {
    return (
      <div className={classes.Loading}>
        <div className={classes.background}/>
        <div className={classes.content}>
          <div className={classes.progressBar}>
            <div className={classes.progress} style={{ width: `${progress}%` }} />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Loading;