import * as me from 'melonjs';

class JumpAnimation {
    /**
     * 
     * @param {me.Entity} entity 
     */
    constructor(entity) {
        this.y = 0;
        this.height = 0;
        this.speed = 1;
        this.targetY = 0;
        this.active = false;

        this.entity = entity;
    }

    start(height) {
        this.y = this.entity.pos.y;
        this.height = height;
        this.targetY = this.y - this.height;
        this.active = true;

        this.entity.body.ignoreGravity = true;
    }
    stop() {
        this.active = false;

        this.entity.body.ignoreGravity = false;
    }

    update(dt) {
        if (!this.isActive())
            return;


        var dist = Math.abs(this.targetY - this.entity.pos.y);
        var vel = Math.round(Math.min(Math.max(1, Math.ceil(Math.sqrt(dist) * this.speed * this.entity.body.gravityScale)), this.entity.body.maxVel.y));

        //this.entity.pos.set(this.entity.pos.x, this.entity.pos.y - vel);
        this.entity.body.vel.set(this.entity.body.vel.x, -vel);
        if (this.entity.pos.y < this.targetY) {
            this.stop();
        }
    }

    isActive() {
        return this.active;
    }
}

export default JumpAnimation;