const resources = [
    { name: "PlayerUp", type: "image", src: "/assets/PlayerUp.png" },
    { name: "PlayerDown", type: "image", src: "/assets/PlayerDown.png" },
    { name: "PlayerDie", type: "image", src: "/assets/PlayerDie.png" },
    { name: "platform", type: "image", src: "/assets/platform.png" },
    { name: "carrot", type: "image", src: "/assets/carrot.png" },
    { name: "Back", type: "image", src: "/assets/Back.png" },
    { name: "back_center", type: "image", src: "/assets/back_center.png" },
    { name: "Grass", type: "image", src: "/assets/Grass.png" },
    { name: "side", type: "image", src: "/assets/side.png" },
    { name: "joystick0", type: "image", src: "/assets/joystick0.png" },
    { name: "joystick1", type: "image", src: "/assets/joystick1.png" },
    { name: "joystick2", type: "image", src: "/assets/joystick2.png" },
    { name: "GameOver", type: "image", src: "/assets/GameOver.png" },
    { name: "GameOverBackground", type: "image", src: "/assets/GameOverBackground.png" },
    { name: "ButtonRetry", type: "image", src: "/assets/ButtonRetry.png" },
    { name: "ButtonExit", type: "image", src: "assets/ButtonExit.png" },
    { name: "Jump", type: "audio", src: "/assets/audio/" },
    { name: "Background", type: "audio", src: "/assets/audio/" }
];

export default resources;
