import axios from "axios";
import { decode } from "./userApi";

export const feed = async (token) => {
  try {
    const response = await axios.post(
      `https://api.hare-online.com/meel/${decode(token)}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (err) {
    console.error("Ошибка при кормлении", err);
    return null;
  }
};
