import classes from './CarrotBalance.module.css'
import orangeValue from '../../../assets/images/orangeValue.svg'
import goldValue from '../../../assets/images/goldValue.svg'
import diamondValue from '../../../assets/images/diamondValue.svg'

const CarrotBalance = ({ value, type }) => {
  return (
    <div className={classes.CarrotBalance}>
      {type === 'orange' && <img src={orangeValue} alt='carrot' className={classes.orangeValue}/>}
      {type === 'gold' && <img src={goldValue} alt='carrot' className={classes.goldValue}/>}
      {type === 'diamond' && <img src={diamondValue} alt='carrot' className={classes.diamondValue}/>}
      <p className={classes.value}>{value}</p>
    </div>
  )
}

export default CarrotBalance;