const { default: GenerationUnits } = require("./GenerationUnits");
const { default: ObjectGenerator } = require("./ObjectGenerator");
const { default: PlatformPlacer } = require("./PlatformPlacer");


class PlatformObjectGenerator extends ObjectGenerator {
    constructor(config) {
        super(config, undefined);
    }

    generateNext() {
        for(var i = 0; i < this.config.count; i++) {
            PlatformPlacer.placeOn(this.config.index, GenerationUnits.getPlatformNumber(), this.config.getNextVariant());
        }
        
        this.nextPositionIncrement();
    }
}

export default PlatformObjectGenerator;