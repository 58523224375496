import * as me from 'melonjs';
import config from "../../components/configs/entityParameters.json";

class Camera extends me.Entity {
    /** @type {Camera} */
    static instance;

    static moveToY(y) {
        this.instance.moveToY(y);
    }

    static getTargetY() {
        return this.instance.getTargetY();
    }

    static getY() {
        return this.instance.getY();
    }

    static setY(y) {
        this.instance.setY(y);
    }

    static reset() {
        this.instance.reset();
    }

    constructor() {
        super(0, 0, { width: 1, height: 1 });
        this.alwaysUpdate = true;

        this.type = 'camera';
        Camera.instance = this;

        this.body.setStatic(true);

        this.reset();
    }

    onDestroyEvent() {
        throw new Error('Camera instance was destroyed.');
    }

    reset() {
        const initialY = -me.game.viewport.height * 0.9;
        this.setY(initialY);
        this.moveToY(initialY);
        this.updateCameraPosition();
        console.log("Camera reset to position: " + me.game.viewport.pos.y);
    }

    update(dt) {
        const dist = this.targetY - this.getY();
        if (Math.abs(dist) > 1) {  // Добавлено условие для уменьшения ненужных перемещений камеры
            const speed = Math.round(dist * config.camera.speed);
            this.setY(this.getY() + speed);
            this.updateCameraPosition();
        }

        return super.update(dt);
    }

    moveToY(y) {
        this.targetY = y;
    }

    updateCameraPosition() {
        me.game.viewport.pos.set(0, Math.round(this.pos.y));
        me.game.viewport.updateBounds();
    }

    getTargetY() {
        return this.targetY;
    }

    getY() {
        return this.pos.y;
    }

    setY(y) {
        this.pos.y = y;
    }
}

export default Camera;
