import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step: 1,
};

const onboardingSlice = createSlice({
  name: 'step',
  initialState,
  reducers: {
    setStep(state, action) {
      state.step = action.payload;
    },
  },
});

export const { setStep } = onboardingSlice.actions;

export default onboardingSlice.reducer;