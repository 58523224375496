import * as me from 'melonjs';

class GameInput {
    pointerReleased = false;

    clear() {
        this.pointerReleased = false;
    }
    check() {
        var result = this.pointerReleased;
        this.clear();
        return result;
    }
    registerPointerRelease() {
        this.pointerReleased = true;
    }
}

class GameStateController {
    start(screenElement) {
        if (screenElement != null) {
            me.state.change(me.state.PLAY);
            console.log('start')

            screenElement.appendChild(me.game.renderer.getCanvas());
        }
    }
    exit() {
        console.log("exit")
        me.state.change(me.state.MENU);
        setTimeout(this.start.bind(this), 0)
    }
    restart() {
        me.state.current().onResetEvent();
    }
}

var game = {

    /**
     * object where to store game global scole
     */
    data : {
        // score
        score : 0
    },
    input: new GameInput(),
    gameController: new GameStateController(),

    // a reference to the texture atlas
    texture : null
};

export default game;