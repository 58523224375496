import { useDispatch, useSelector } from 'react-redux';
import classes from './Earn.module.css'
import TaskItem from '../../ui/TaskItem/TaskItem';
import taskBg from '../../../assets/images/taskbg.png'
import vintik from '../../../assets/images/vintik.png'
import metalVintik from '../../../assets/images/metalVintik.png'
import earnBg from '../../../assets/images/earnImg.png'
import Home from '../Home/Home';
import close from '../../../assets/images/closeButton.png'
import { useCallback, useEffect, useMemo } from 'react';
import { setTasks, updateButtonState } from '../../../store/tasksSlice';
import { checkLinkTask, checkTask, getTasks } from '../../../api/taskApi';
import { updateBalance, updateRegular } from '../../../store/userSlice';

const Earn = ({ onClose }) => {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token.token);

  const dispatch = useDispatch()
  const { loadedTasks, dailyTasks, specialTasks, buttonsState } = useSelector((state) => state.tasks);

  const fetchTasks = useCallback(async () => {
    if (!loadedTasks.length) {
      console.log(loadedTasks)
      const response = await getTasks(token)
      dispatch(setTasks(response))
    }
  }, [token, loadedTasks])

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks])

  const onLinkButtonClick = async(id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentState?.status === 'not_success') {
      // Открываем ссылку, если задача ещё не началась
      tg.openLink(currentTask.link);
      dispatch(updateButtonState({ id: id, status: 'ready_to_claim'}));
    } else if (currentState?.status === 'ready_to_claim') {
      const response = await checkLinkTask(id, token)
      dispatch(updateBalance({ balance: Math.floor(response[0].new_balance)}))
      dispatch(updateButtonState({ id: id, status: 'success'}));
    }
  }

  const onSubButtonClick = async (id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
  
    if (currentState?.status === 'not_success') {
      // Открываем ссылку, если задача ещё не началась
      tg.openTelegramLink(currentTask.link);
      dispatch(updateButtonState({ id: id, status: 'started' })); // Обновляем статус на 'started'
    } else if (currentState?.status === 'started') {
      // Проверяем задачу, если она в статусе 'started'
      const response = await checkTask(id, token);
      if (response[0].status === 'success') {
        dispatch(updateBalance({ balance: Math.floor(response[0].new_balance)}))
        dispatch(updateButtonState({ id: id, status: 'success' })); // Обновляем статус на 'ready_to_claim'
      }
    } else if (currentState?.status === 'success') {
      console.log('claimed'); // Если задача уже выполнена
    }
  };

  const onClick = (id, token) => {
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    if (currentTask.task_type === 'link') {
      onLinkButtonClick(id, token)
    } else {
      onSubButtonClick(id, token)
    }
  }
  
  const memoizedCampTasks = useMemo(() => (
    dailyTasks.map((task) => (
      <TaskItem
        title={task.title}
        reward={task.reward}
        onClick={() => onClick(task.id, token)}
        status={buttonsState.find(item => item.id === task.id).status}
        img={task.image_url}
      />
    ))
  ), [dailyTasks, buttonsState]);

  const memoizedSpecTasks = useMemo(() => (
    specialTasks.map((task) => (
      <TaskItem
        title={task.title}
        reward={task.reward}
        onClick={() => onClick(task.id, token)}
        status={buttonsState.find(item => item.id === task.id).status}
        img={task.image_url}
      />
    ))
  ), [specialTasks, buttonsState]);

  return (
    <div className={classes.Earn}>
      <div className={classes.cross} onClick={() => onClose()}>
        <img src={close} alt='close' className={classes.close}/>
      </div>
      <img src={'/assets/earnImg.png'} alt='bg' className={classes.earnBg}/>
      <div className={classes.balanceContainer}>
        <div className={classes.hareBalance}>
          <p className={classes.balanceTitle}>HARE BALANCE</p>
          <p className={classes.balanceValue}>{Math.floor(user.balance)}<span className={classes.fontBack}>{Math.floor(user.balance)}</span></p>
        </div>
      </div>
      <div className={classes.tasksContainer}>
        <img src={taskBg} alt='bg' className={classes.taskBg}/>
        <div className={classes.taskSign}>
          <div className={classes.above}>
            <img src={metalVintik} alt='decor' className={classes.metalVintik}/>
            <p className={classes.aboveText}>QUESTS</p>
            <img src={metalVintik} alt='decor' className={classes.metalVintik}/>
          </div>
          <img src={vintik} alt='decor' className={classes.vintik}/>
          <div className={classes.signContainer}>
            <p className={classes.signText}>Get rewards every day<br/>for your activity</p>
          </div>
          <img src={vintik} alt='decor' className={classes.vintik}/>
        </div>
        <div className={classes.scroll}>
          <div className={classes.category}>
            <p className={classes.categoryTitle}>SPECIAL</p>
            {memoizedSpecTasks}
          </div>
          <div className={classes.category}>
            <p className={classes.categoryTitle}>CAMPAINGS</p>
            {memoizedCampTasks}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Earn