const { ObjectGenerationConfig } = require("../../components/configs/GeneratorConfigClasses");
const { default: GenerationUnits } = require("./GenerationUnits");
const { default: ObjectPlacer } = require("./ObjectPlacer");

class ObjectGenerator {
    /**
     * 
     * @param {ObjectGenerationConfig} config 
     * @param {ObjectPlacer} placer 
     */
    constructor(config, placer) {
        this.config = config;
        this.unit = this.config.unit;
        this.placer = placer;

        this.lastGenerationPosition = 0;
        this.nextGenerationPosition = this.getNewGenerationPosition(this.unit);
        console.log("genpos: " + this.nextGenerationPosition);
        this.nextPositionIncrement();
    }

    generate() {
        while (this.checkGenerationNeeded()) {
            this.generateNext();
        }
    }
    generateNext() {
        var variant = this.config.getNextVariant();
        this.placer.placeRandomlyToScreenWidth(this.config.index, this.getNextWorldPosition(), variant);
        this.nextPositionIncrement();
    }
    checkGenerationNeeded() {
        return this.getNewGenerationPosition() >= this.nextGenerationPosition;
    }

    nextPositionIncrement() {
        this.lastGenerationPosition = this.nextGenerationPosition;
        this.nextGenerationPosition += this.getRandomDistance();
        //console.log("nextPositionIncrement, last: " + this.lastGenerationPosition +  ", next: " + this.nextGenerationPosition + ", dist: " + (this.nextGenerationPosition - this.lastGenerationPosition));
    }
    getRandomDistance() {
        if (this.unit == 'platform') {
            return Math.round(this.getRawRandomDistance());
        }
        return this.getRawRandomDistance();
    }
    getRawRandomDistance() {
        return Math.random() * (this.config.distanceMax - this.config.distanceMin) + this.config.distanceMin;
    }

    getNextWorldPosition() {
        return GenerationUnits.getWorldPixelPositionOf(this.unit, this.nextGenerationPosition);
    }
    getNewGenerationPosition() {
        return GenerationUnits.getPosition(this.unit);
    }

    /** @param {ObjectGenerationConfig} config  */
    setConfig(config) {
        this.config = config;
    }

}

export default ObjectGenerator;