import * as config from './GeneratorConfigClasses'

class ProdGenerationConfig extends config.GenerationConfig {
    constructor() {
        super();
        this.addLevel(new Level0());
        this.addLevel(new Level1());

        this.defaultPlatform = "p1";
    }
}

class Level0 extends config.GenerationSet {
    constructor() {
        super();

        this.length = 30; // count of platforms for this difficult level

        //                                    types         chances (the sum of the chances can be any, not only 100)
        this.addPlatformGenerator(0.4, 0.48, [['p1', 'p11'], [70, 30]]);
        this.addOnPlatformObjectGenerator('carrot', 7, 10);
    }
}

class Level1 extends config.GenerationSet {
    constructor() {
        super();

        this.length = 70;

        this.addPlatformGenerator(0.45, 0.48, [['p1', 'p11'], [60, 40]]);
        this.addOnPlatformObjectGenerator('carrot', 6, 7);

        this.addFloatingObjectGenerator('carrot', 9, 11, 'platform');
    }
}

export default ProdGenerationConfig;