import { 
    game, 
    Container, 
    Sprite,
    loader,
    UISpriteElement,
    Vector2d, 
} from 'melonjs';

import gameController from '../../game';
import EntityScaler from '../Tools/EntityScaler';
import PlayScreen from '../../Screens/PlayScreen';
import WebSocketClient from '../../../api/wsApi';
//import { postData } from '../../../utils/api';

class GameOverPopup extends Container {
    constructor() {
        super(0, 0, game.viewport.width, game.viewport.height);

        const backImageName = "GameOverBackground";
        const backImage = loader.getImage(backImageName);

        this.background = new Sprite(game.viewport.width / 2, game.viewport.height / 2, {
            width: backImage.width,
            height: backImage.height,
            image: backImageName
        });
        this.addChild(this.background, -1000);
        var scale = EntityScaler.getScale(100, 100, backImage.width, backImage.height);
        this.background.scaleV(scale);

        this.content = new GameOverPopupContent(0, this.getBounds().height / 2);
        this.addChild(this.content, 1000);

        this.depth = 9999;
        this.floating = true;

        this.animation = 0;
        this.animationLength = 0.5;
    }

    update(dt) {
        super.update(dt);

        this.animation += dt * 0.001;
        var t = Math.min(this.animation / this.animationLength, 1);
        this.setOpacity(t);
    }

    show() {
        game.world.addChild(this);
    }
}

class GameOverPopupContent extends Container {
    constructor(x, y) {
        const imageBackgroundName = 'GameOver';
        const imageBackground = loader.getImage(imageBackgroundName);

        super(x, y, game.viewport.width, game.viewport.height);

        this.background = new Sprite(this.width / 2, this.height / 2, {
            image: imageBackgroundName,
            width: imageBackground.width,
            height: imageBackground.height,
            anchorPoint: new Vector2d(0, 0)
        });
        this.addChild(this.background);

        this.buttonY = this.height * 0.6;
        this.buttonX1 = this.width * 0.35;
        this.buttonX2 = this.width * 0.67;
        var buttonRelativeWidth = 30;

        this.buttonAgain = new Button(this.buttonX1, this.buttonY, buttonRelativeWidth, 'ButtonRetry', async () => {
          
              WebSocketClient.sendMessage('game stop');
              WebSocketClient.sendMessage('game start');
              gameController.gameController.restart();
              
        });

        // Проверяем уровень стамины и прячем кнопку "Заново", если стамины недостаточно
        if (false) {
            this.buttonAgain.visible = false;  // Скрываем кнопку "Заново"
        }

        this.addChild(this.buttonAgain);

        this.buttonExit = new Button(this.buttonX2, this.buttonY, buttonRelativeWidth, 'ButtonExit', () => { 
            PlayScreen.instance.exitGameFunction();
        });
        this.addChild(this.buttonExit);

        EntityScaler.scaleToFitWidth(this.background, 100);
        this.background.pos.x = this.width / 2 - this.background.width / 2;
        this.background.pos.y = this.height / 2 - this.background.height / 2;

        this.floating = true;

        this.pos.y -= this.getBounds().height / 2;

        this.animation = 0;
        this.animationLength = 0.5;
        this.targetY = this.pos.y;
        this.startY = this.pos.y + game.viewport.height;
        this.pos.y = this.startY;

        this.alwaysUpdate = true;
    }

    update(dt) {
        this.animation += dt * 0.001;

        var t = Math.min(this.animation / this.animationLength, 1);
        t = Math.pow(t, 0.1);
        this.pos.set(0, (this.targetY - this.startY) * t + this.startY);

        super.update(dt);
    }
}

class Button extends UISpriteElement {
    constructor(x, y, width, imageName, action) {
        var image = loader.getImage(imageName);

        super(x, y, {
            image: imageName,
            width: image.width,
            height: image.height
        });

        this.floating = false;

        var w = this.width;
        var h = this.height;
        EntityScaler.scaleToFitWidth(this, width);
        this.width = w;
        this.height = h;

        this.action = action;
    }

    onClick(event) {
        this.action();
    }
}

export default GameOverPopup;
