import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  content: '',
};

const oopsSlice = createSlice({
  name: 'oops',
  initialState,
  reducers: {
    setOpen(state) {
      state.isOpen = true;
    },
    setClose(state) {
      state.isOpen = false;
    },
    setContent(state, action) {
      state.content = action.payload
    },
  },
});

export const { setOpen, setClose, setContent } = oopsSlice.actions;

export default oopsSlice.reducer;