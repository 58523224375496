import * as me from 'melonjs'
import PlatformEntity from '../Entities/Level/Platform';
import EntityScaler from '../Entities/Tools/EntityScaler';
import WorldEntity from '../Entities/Level/WorldEntity';
import CarrotEntity from '../Entities/Level/Collectable/Carrot';

class PlatformPlacer {
    constructor() {
        this.collection = new PlatformInfoCollection();
    }

    placeOn(index, platformIndex, variant=undefined) {
        var info = this.getInfo(platformIndex);
        info.place(index, variant);
    }
    placeEntityOn(entity, platformIndex) {
        var info = this.getInfo(platformIndex);
        info.placeEntity(entity);
    }

    getInfo(index) {
        return this.collection.getPlatformInfoOrCreate(index);
    }

    clear() {
        this.collection = new PlatformInfoCollection();
    }
}

class PlatformInfoCollection {
    /** @type {PlatformInfo[]} */
    platformInfos = [];
    maxSize = 50;

    clear() {
        this.platformInfos = [];
    }

    getPlatformInfoOrCreate(index) {
        var info = this.getPlatformInfo(index);
        if (typeof(info) == 'undefined') {
            info = this.registerPlatform(index);
        }

        return info;
    }

    registerPlatform(index) {
        if (this.isRegistered(index)) {
            return;
        }

        var info = new PlatformInfo(index);

        this.platformInfos.push(info);
        this.cutSize();

        return info;
    }
    unregisterPlatform(index) {
        var info = this.getPlatformInfo(index);
        if (typeof(info) == 'undefined') {
            return;
        }
        this.platformInfos.splice(this.platformInfos.indexOf(info), 1);
    }
    cutSize() {
        for(;this.platformInfos.length > this.maxSize;) {
            this.unregisterPlatform(this.platformInfos[0].index);
        }
    }
    isRegistered(index) {
        return this.getPlatformInfo(index) != undefined;
    }
    getPlatformInfo(index) {
        for(var info of this.platformInfos) {
            if (info.index == index) {
                return info;
            }
        }
        return undefined;
    }
}

class PlatformInfo {
    /** @type {WorldEntity[]} */
    list = [];

    constructor(index) {
        this.index = index;
        this.step = EntityScaler.scaleToWidth(1);
        this.stepVertical = EntityScaler.scaleToHeight(1);
    }

    place(index, variant=undefined) {
        var entityClass = undefined;

        switch(index) {
            case 'carrot': entityClass = CarrotEntity; break;
        }

        var entity = new entityClass(0, 0, variant);
        me.game.world.addChild(entity);
        this.placeEntity(entity);
    }
    placeEntity(entity) {
        this.list.push(entity);
        this.updatePositions();
    }

    updatePositions() {
        var w = this.getAllWidth();
        var platform = this.getPlatform();

        var startX = platform.pos.x + platform.body.getBounds().centerX - w/2;

        var x = startX;
        var y = platform.pos.y + platform.body.getBounds().top - this.stepVertical;

        for(var entity of this.list) {
            entity.setPos(x, y, 0, 1);
            x += entity.width + this.step;
        }
    }
    getAllWidth() {
        var w = 0;
        for(var entity of this.list) {
            w += entity.width + this.step;
        }
        w -= this.step;

        return w;
    }
    getPlatform() {
        return PlatformEntity.getPlatformUnderIndex(this.index);
    }
}

export default new PlatformPlacer();