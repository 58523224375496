import classes from './Boost.module.css'
import bg from '../../../assets/images/boostBg.svg'
import carrot from '../../../assets/images/orangeValue.svg'
import bdiamond from '../../../assets/images/b-diamond.png'
import bstamina from '../../../assets/images/b-stamina.png'
import bmeal from '../../../assets/images/b-meal.png'
import bmining from '../../../assets/images/b-mining.png'
import close from '../../../assets/images/closeButton.png'
import { updateBalance, updateEnergy, updateEnergyBoostLevel, updateMiningLevel, updateMiningSecodns, updateRegular, updateTapBoostLevel } from '../../../store/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { claim, upgradeEnergy, upgradeMining, upgradeMultitap } from '../../../api/boosterApi'
import { setContent, setOpen } from '../../../store/oopsSlice'

const Boost = ({ isOpen, onClose, soonOpen }) => {

  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token.token)
  const dispatch = useDispatch()

  const miningUpgradeHandler = async () => {
  
  const response = await upgradeMining(token)
    if (user.mining_level < 20) {
      if (response.status === 'success') {
        dispatch(updateMiningSecodns({ mining_claim_seconds: response.mining_claim_seconds }))
        dispatch(updateRegular({ regular_carrots: response.regular_carrots }))
        dispatch(updateMiningLevel({ mining_level: response.mining_level }))
        dispatch(updateBalance({ balance: response.balance }))
      } else {
        dispatch(setOpen())
        dispatch(setContent(<p>Need {formatNumberToM(1000 * (2**(user.mining_level)))} <img src={carrot} alt='carrot' className={classes.oopsCarrot}/> to upgrade</p>))
      }
    }
  }

  const staminaUpgradeHandler = async () => {
    if (user.energy_boost_level < 8) {
      const response = await upgradeEnergy(token)
      if (response.status === 'success') {
        dispatch(updateRegular({ regular_carrots: response.regular_carrots }))
        dispatch(updateEnergyBoostLevel({ energy_boost_level: response.energy_boost_level }))
      } else {
        dispatch(setOpen())
        dispatch(setContent(<p>Need {(100 * (2**(user.energy_boost_level)))} <img src={carrot} alt='carrot' className={classes.oopsCarrot}/> to upgrade</p>))
      }
    }
  }

  const mealUpgradeHandler = async () => {
    if (user.tap_boost_level < 18) {
      const response = await upgradeMultitap(token)
      if (response.status === 'success') {
        dispatch(updateRegular({ regular_carrots: response.regular_carrots }))
        dispatch(updateTapBoostLevel({ tap_boost_level: response.tap_boost_level }))
      } else {
        dispatch(setOpen())
        dispatch(setContent(<p>Need {(100 * (2**(user.energy_boost_level)))} <img src={carrot} alt='carrot' className={classes.oopsCarrot}/>to upgrade</p>))
      }
    }
  }

  const formatNumberToM = (num) => {
    if (num < 1000000) return num;
    return (num / 1000000).toFixed(2).replace(/\.00$/, '') + 'M';
  };

  if (!isOpen) return null;

  return (
    <div className={classes.overlay} onClick={onClose}>
      <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
        <div className={classes.cross} onClick={() => onClose()}>
          <img src={close} alt='close' className={classes.close}/>
        </div>
        <img src={'/assets/boostBg.svg'} alt='bg' className={classes.bg}/>
        <div className={classes.content}>
          {/*diamond*/}
          <div className={classes.boostItem}>
            <div className={classes.left}>
              <div className={classes.boostImgFrame}>
              <img src={bdiamond} alt='boost' className={classes.bdiamond}/>
              </div>
              <div className={classes.boostText}>
                1 Diamond Carrot
              </div>
            </div>
            <div className={classes.boostButton} onClick={soonOpen}>
              1 TON
            </div>
          </div>
          {/*stamina*/}
          <div className={classes.boostItem}>
            <div className={classes.left}>
              <div className={classes.boostImgFrame}>
                <div className={classes.level}>{user.energy_boost_level}</div>
                <img src={bstamina} alt='boost' className={classes.bstamina}/>
              </div>
              <div className={classes.boostText}>
                <p>Stamina boost</p>
                <p className={classes.lvl}><span><img src={carrot} alt='carrot' className={classes.carrot}/></span>{(100 * (2**(user.energy_boost_level)))}/lvl</p>
              </div>
            </div>
            <div className={classes.boostButton} onClick={staminaUpgradeHandler} style={{opacity: user.energy_boost_level >= 8 ? '50%' : '100%'}}>
              UPGRADE
            </div>
          </div>
          {/*meal*/}
          <div className={classes.boostItem}>
            <div className={classes.left}>
              <div className={classes.boostImgFrame}>
              <div className={classes.level}>{user.tap_boost_level - 10}</div>
                <img src={bmeal} alt='boost' className={classes.bmeal}/>
              </div>
              <div className={classes.boostText}>
                <p>Meal boost</p>
                <p className={classes.lvl}><span><img src={carrot} alt='carrot' className={classes.carrot}/></span>{(100 * (2**(user.tap_boost_level - 10)))}/lvl</p>
              </div>
            </div>
            <div className={classes.boostButton} onClick={mealUpgradeHandler} style={{opacity: user.tap_boost_level >= 18 ? '50%' : '100%'}}>
              UPGRADE
            </div>
          </div>
          {/*mining*/}
          <div className={classes.boostItem}>
            <div className={classes.left}>
              <div className={classes.boostImgFrame}>
                <div className={classes.level}>{user.mining_level}</div>
                <img src={bmining} alt='boost' className={classes.bmining}/>
              </div>
              <div className={classes.boostText}>
                <p>Hare Mining</p>
                <p className={classes.lvl}><span><img src={carrot} alt='carrot' className={classes.carrot}/></span>{formatNumberToM(1000 * (2**(user.mining_level)))}/lvl</p>
              </div>
            </div>
            <div className={classes.boostButton} onClick={miningUpgradeHandler} style={{opacity: user.tap_boost_level >= 20 ? '50%' : '100%'}}>
              UPGRADE
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boost