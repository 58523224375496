import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice'
import leagueReducer from './leagueSlice'
import friendsReducer from './friendsSlice'
import linkReducer from './linkSlice';
import tasksReducer from './tasksSlice';
import tokenReducer from './tokenSlice';
import oopsReducer from './oopsSlice';
import onboardingReducer from './onboardingSlice';


export const store = configureStore({
  reducer: {
    user: userReducer,
    league: leagueReducer,
    friends: friendsReducer,
    link: linkReducer,
    tasks: tasksReducer,
    token: tokenReducer,
    oops: oopsReducer,
    step: onboardingReducer,
  },
});
