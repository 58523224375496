import { Stage, audio, game as meGame } from 'melonjs';
import game from './../game.js';
import VirtualJoypad from '../Entities/Interface/controls.js';
import PlayerEntity from '../Entities/Player.js';
import PlatformEntity from '../Entities/Level/Platform.js';
import LevelGenerator from '../LevelGenerator/LevelGenerator.js';
import Back from '../Entities/Level/Decoration/Background.js';
import Camera from '../Entities/Camera.js';
import audioConfig from "../../components/configs/audio.json";

class PlayScreen extends Stage {
    static instance;

    constructor(exitGameFunction) {
        super();
        PlayScreen.instance = this;
        this.exitGameFunction = exitGameFunction;
    }

    onResetEvent() {
        this.clear();
        game.data.score = 0;

        if (!this.camera) {
            this.camera = new Camera();
        }

        meGame.world.addChild(this.camera);
        this.camera.reset();

        meGame.world.addChild(new Back());
        meGame.world.addChild(new LevelGenerator());

        meGame.world.autoSort = true;
        meGame.world.sortOn = "z";
        meGame.world.autoDepth = false;

        this.virtualJoypad = new VirtualJoypad(90, 90);
        meGame.world.addChild(this.virtualJoypad);

        audio.playTrack("Background", audioConfig.background.volume);
    }

    onDestroyEvent() {
        this.clear();
    }

    clear() {
        audio.stopTrack("Background");
        for (let i = meGame.world.children.length - 1; i >= 0; i--) {
            const child = meGame.world.children[i];
            try {
                meGame.world.removeChildNow(child, (child.type === 'camera' || child.type === 'interface'));
            } catch (e) {
                console.error(e);
            }
        }
    }
}

export default PlayScreen;
