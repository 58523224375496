import { Entity, Rect, collision, input, audio, loader, game as meGame } from 'melonjs';
import game from './../game.js';
import EntityScaler from './Tools/EntityScaler.js';

import entityParameters from '../../components/configs/entityParameters.json';
import Camera from './Camera.js';
import DeadPlayer from './Level/DeadPlayer.js';
import JumpAnimation from './Tools/JumpAnimation.js';

import audioConfig from "../../components/configs/audio.json";
import GameOverPopup from './Interface/GameOverPopup.js';
//import { fetchUserData } from '../../utils/api.js';

const imagePlayerUp = 'PlayerUp';
const imagePlayerDown = 'PlayerDown';

const screenPieceUnderPlayer = 9;

class PlayerEntity extends Entity {
    constructor(x, y) {
        const image = loader.getImage(imagePlayerUp);
        
        super(x, y, {
            image: imagePlayerUp,
            width: image.width,
            height: image.height,
        });

        this.isAlive = true;
        this.z = 1000;

        this.simpleJumpHeight = EntityScaler.scaleToHeight(entityParameters.player.jump_height);
        this.strongJumpHeight = EntityScaler.scaleToHeight(entityParameters.player.jump_height_strong);
        this.superJumpHeight = EntityScaler.scaleToHeight(entityParameters.player.jump_height_super);

        this.jumpAnimation = new JumpAnimation(this);

        this.verticalScale = Math.sqrt(EntityScaler.getScaleToFit(10, 10, 100, 100, 'h'));
        this.body.gravityScale = entityParameters.player.gravity * this.verticalScale;
        this.body.collisionType = collision.types.PLAYER_OBJECT;

        this.alwaysUpdate = true;

        this.body.setMaxVelocity(EntityScaler.scaleToWidth(entityParameters.player.speed) / 60, 24 * this.verticalScale);
        this.body.setFriction(1, 0);

        this.anchorPoint.set(0.5, 1);
        this.renderable.anchorPoint.set(0.5, 1);

        EntityScaler.scaleToFitWidth(this, entityParameters.player.width);

        this.body.removeShapeAt(0);
        this.body.addShape(new Rect(0, 0, this.width, EntityScaler.scaleToHeight(entityParameters.player.height)));

        this.dying = false;

        this.frameCount = 0; // Инициализация счетчика кадров

        // Enable keyboard
        input.bindKey(input.KEY.LEFT, "left");
        input.bindKey(input.KEY.RIGHT, "right");
        input.bindKey(input.KEY.X, "jump", true);
        input.bindKey(input.KEY.UP, "jump", true);
        input.bindKey(input.KEY.SPACE, "jump", true);
        input.bindKey(input.KEY.DOWN, "down");

        input.bindKey(input.KEY.A, "left");
        input.bindKey(input.KEY.D, "right");
        input.bindKey(input.KEY.W, "jump", true);
        input.bindKey(input.KEY.S, "down");

        input.bindGamepad(0, { type: "buttons", code: input.GAMEPAD.BUTTONS.FACE_1 }, input.KEY.UP);
        input.bindGamepad(0, { type: "buttons", code: input.GAMEPAD.BUTTONS.FACE_2 }, input.KEY.UP);
        input.bindGamepad(0, { type: "buttons", code: input.GAMEPAD.BUTTONS.DOWN }, input.KEY.DOWN);
        input.bindGamepad(0, { type: "buttons", code: input.GAMEPAD.BUTTONS.FACE_3 }, input.KEY.DOWN);
        input.bindGamepad(0, { type: "buttons", code: input.GAMEPAD.BUTTONS.FACE_4 }, input.KEY.DOWN);
        input.bindGamepad(0, { type: "buttons", code: input.GAMEPAD.BUTTONS.LEFT }, input.KEY.LEFT);
        input.bindGamepad(0, { type: "buttons", code: input.GAMEPAD.BUTTONS.RIGHT }, input.KEY.RIGHT);

        input.bindGamepad(0, { type: "axes", code: input.GAMEPAD.AXES.LX, threshold: -0.5 }, input.KEY.LEFT);
        input.bindGamepad(0, { type: "axes", code: input.GAMEPAD.AXES.LX, threshold: 0.5 }, input.KEY.RIGHT);
        input.bindGamepad(0, { type: "axes", code: input.GAMEPAD.AXES.LY, threshold: -0.5 }, input.KEY.UP);

        Camera.setY(this.predictCameraPos());
        Camera.moveToY(this.predictCameraPos() - 4);
    }

    draw(renderer, viewport) {
        if (!this.isAlive) return;

        return super.draw(renderer, viewport);
    }

    update(dt) {
        if (!this.isAlive) {
            return;
        }

        this.frameCount++;
        
        // Обновляем физику только каждые 2 кадра
        if (this.frameCount % 1 === 0) {
            this.updateCamera();
            this.checkBorders();
            this.checkInput();
            this.processJumping(dt);
            this.checkFalling();
        }
        
        return super.update(dt);
    }

    checkBorders() {
        if (this.centerX > meGame.viewport.width && this.body.vel.x > 0) {
            this.pos.set(-this.width / 2, this.pos.y);
        }
        if (this.centerX < 0 && this.body.vel.x < 0) {
            this.pos.set(meGame.viewport.width - this.width / 2, this.pos.y);
        }
    }

    updateCamera() {
        const cameraPos = this.getTargetCameraPos();
        Camera.moveToY(Math.min(Camera.getTargetY(), cameraPos));
    }

    predictCameraPos() {
        return this.getTargetCameraPos() - this.simpleJumpHeight;
    }

    getTargetCameraPos() {
        const targetPos = this.pos.y + this.height + this.simpleJumpHeight + EntityScaler.scaleToHeight(screenPieceUnderPlayer) - meGame.viewport.height;
        return targetPos;
    }

    checkInput() {
        if (input.isKeyPressed("left")) {
            this.renderable.flipX(true);
            this.body.force.x = -this.body.maxVel.x;
        } else if (input.isKeyPressed("right")) {
            this.renderable.flipX(false);
            this.body.force.x = this.body.maxVel.x;
        }

        this.renderable.image = this.body.vel.y < 0 ? loader.getImage(imagePlayerUp) : loader.getImage(imagePlayerDown);
    }

    processJumping(dt) {
        this.jumpAnimation.update(dt);
    }

    checkFalling() {
        if (this.getBounds().top > meGame.viewport.getBounds().bottom) {
            this.loose();
        }
    }

    loose() {
        //fetchUserData()
        this.isAlive = false;
        this.body.vel.y = -EntityScaler.scaleToHeight(5);
        meGame.world.addChild(new DeadPlayer(this), 1000);

        setTimeout(this.showGameOverPopup.bind(this), 1000);
    }

    showGameOverPopup() {
        new GameOverPopup().show();
    }

    onCollision(response, other) {
        switch (other.body.collisionType) {
            case collision.types.WORLD_SHAPE:
                if (other.type === "platform") {
                    if (this.body.vel.y < 0 || (this.body.getBounds().bottom + this.pos.y > other.body.getBounds().top + other.pos.y + this.body.vel.y + 4)) {
                        return false;
                    }
                    this.pos.y = other.body.getBounds().top + other.pos.y - this.body.getBounds().height;
                    this.jumpSimple();
                    response.overlapV.x = 0;
                    return false;
                } else {
                    response.overlapV.y = Math.abs(response.overlap);
                    response.overlapV.x = 0;
                    return true;
                }
            case collision.types.COLLECTABLE_OBJECT:
                game.data.score += other.price;
                other.ancestor.removeChild(other);
            default:
                return false;
        }
    }

    jumpSimple() {
        this.jump(this.simpleJumpHeight);
    }

    jumpStrong() {
        this.jump(this.strongJumpHeight);
    }

    jumpSuper() {
        this.jump(this.superJumpHeight);
    }

    jump(height = this.simpleJumpHeight) {
    //    audio.play("Jump", false, undefined, audioConfig.jump.volume);
    this.jumpAnimation.start(height);
    }
}

export default PlayerEntity;
