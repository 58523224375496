import * as me from 'melonjs'
import EntityScaler from '../Tools/EntityScaler';
import { GenerationConfig } from '../../../components/configs/GeneratorConfigClasses';

class WorldEntity extends me.Entity {

    constructor(x, y, settings) {
        super(x, y, settings);
        this.alwaysUpdate = true;
    }

    updatePosInBounds() {
        var limitLeft = GenerationConfig.instance.leftLimitX * me.game.viewport.width;
        var limitRight = GenerationConfig.instance.rightLimitX * me.game.viewport.width;

        if (this.pos.x - this.width/2 < limitLeft) {
            this.pos.x = limitLeft + this.width/2;
        }
        if (this.pos.x + this.width/2 > limitRight) {
            this.pos.x = limitRight - this.width/2;
        }
    }

    update(dt) {
        super.update(dt);

        if (this.body.getBounds().top + this.pos.y > me.game.viewport.getBounds().bottom) {
            this.remove();
            return;
        }
    }

    remove() {
        this.ancestor.removeChild(this);
    }

    setPos(x, y, halign, valign) {
        this.pos.set(x - this.width * (halign), y - this.height * (valign));
    }
    getTop() {
        return this.pos.y - this.height * this.anchorPoint.y;
    }
}

export default WorldEntity;