import axios from "axios";
import { decode } from "./userApi";

export const getReferals = async (token) => {
  try {
    const response = await axios.get(
      `https://api.hare-online.com/referrals/my_referrals/${decode(token)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (err) {
    console.error("Ошибка получения рефералов", err);
    return null;
  }
};

export const getLink = async (token) => {
  try {
    const response = await axios.get(
      `https://api.hare-online.com/referrals/invite_link/${decode(token)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (err) {
    console.error("Ошибка получения реферальной ссылки", err);
    return null;
  }
};
