import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { BrowserRouter, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import NoPhone from './components/pages/NoPhone/NoPhone';

const tg = window.Telegram ? window.Telegram.WebApp : null;
console.log(window.Telegram)
if (tg) {
  console.log(tg)
  tg.expand();
  tg.ready();
  tg.isClosingConfirmationEnabled = true; // Включаем подтверждение перед закрытием
}

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  //True, если мобильное
};

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  //True, если поддерживает тапы
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <WebAppProvider>
      <Provider store={store}>
        <BrowserRouter>
          {isMobileDevice() && isTouchDevice() ? 
            <App />
          :
            <NoPhone/>
          }
        </BrowserRouter>
      </Provider>
    </WebAppProvider>
  </React.StrictMode>
);
