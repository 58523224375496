import classes from './TaskItem.module.css'
import button from '../../../assets/images/m-switchCarrot.png'
import carrot from '../../../assets/images/orangeValue.svg'
const TaskItem = ({ title, link, reward, onClick, status, img }) => {
  return (
    <div className={classes.TaskItem}>
      <div className={classes.taskContainer}>
        <div className={classes.imgContainer}>
          <img src={img} alt='img' className={classes.img}/>
        </div>
        <div className={classes.taskInfo}>
          <p className={classes.questTitle}>{title}</p>
          <div className={classes.rewardInfo}>
            <p className={classes.questReward}>+{reward} HARE</p>
          </div>
        </div>
      </div>
      <div className={classes.button} onClick={onClick} style={{opacity: status === 'success' ? '50%' : '100%'}}>
        <img src={button} alt='button' className={classes.bimg}/>
      </div>
    </div>
  )
}

export default TaskItem