import * as me from 'melonjs'

class EntityScaler {
    
    static scaleToFitWidth(entity, widthPercents) {
        this.scaleToFit(entity, widthPercents, 1, 'w');
    }

    /**
     * @param {('min'|'max'|'median'|'w'|'h')} fitType
     * @param {me.Entity} entity 
     */
    static scaleToFit(entity, widthPercents, heightPercents, fitType) {
        var scale = this.getScaleToFit(widthPercents, heightPercents, entity.width, entity.height, fitType);

        entity.scale(scale, scale);
        if (entity.renderable != undefined)
            entity.renderable.scale(scale, scale);

        entity.width *= scale;
        entity.height *= scale;

        if (entity.body != undefined) {
            entity.body.removeShapeAt(0);
            entity.body.addShape(new me.Rect(0, 0, entity.width, entity.height));
        }
    }

    /**
     * @param {('min'|'max'|'median'|'w'|'h')} fitType
     */
    static getScaleToFit(widthPercents, heightPercents, widthOriginal, heightOriginal, fitType) {
        var scale2d = this.getScale(widthPercents, heightPercents, widthOriginal, heightOriginal);

        var min = Math.min(scale2d.x, scale2d.y);
        var max = Math.min(scale2d.x, scale2d.y);
        var median = (scale2d.x + scale2d.y) * 0.5;
        var w = scale2d.x;
        var h = scale2d.y;

        me.pool.push(scale2d);
        
        switch (fitType) {
            case 'min': return min;
            case 'max': return max;
            case 'median': return median;
            case 'h': return h;
            case 'w': return w;
        }

        throw new Error("undefined fit type " + fitType);
    }

    /**
     * @returns {me.Vector2d}
     */
    static getScale(widthPercents, heightPercents, widthOriginal, heightOriginal) {
        var wReal = this.scaleToWidth(widthPercents);
        var hReal = this.scaleToHeight(heightPercents);

        return this.getScaleReal(wReal, hReal, widthOriginal, heightOriginal)
    }

    /**
     * @returns {me.Vector2d}
     */
    static getScaleReal(widthReal, heightReal, widthOriginal, heightOriginal) {
        var xscale = widthReal/widthOriginal;
        var yscale = heightReal/heightOriginal;

        return me.pool.pull("Vector2d", xscale, yscale);
    }

    static scaleToWidth(widthPercents) {
        return widthPercents / 100 * me.game.viewport.width;
    }
    static scaleToHeight(heightPercents) {
        return heightPercents / 100 * me.game.viewport.height;
    }
}

export default EntityScaler;