import * as me from 'melonjs';
import resources from './resources';
import PlayerEntity from './Entities/Player';
import PlayScreen from './Screens/PlayScreen';
import game from './game';
import PlatformEntity from './Entities/Level/Platform';

let loaded = false;
let musicWorker;
let gameSwipesActive = false;

function preventDefault(e) {
    e.preventDefault();
    e.stopPropagation();
}

function preventAllSwipes() {
    window.addEventListener('touchstart', preventDefault, { passive: false });
    window.addEventListener('touchmove', preventDefault, { passive: false });
    window.addEventListener('touchend', preventDefault, { passive: false });
    window.addEventListener('touchcancel', preventDefault, { passive: false });
    console.log('All swipe gestures are blocked');
}

function enableSwipePrevention() {
    if (gameSwipesActive) return;  // Если уже активировано, ничего не делаем

    gameSwipesActive = true;
    preventAllSwipes();
}

function disableSwipePrevention() {
    if (!gameSwipesActive) return;  // Если не активировано, ничего не делаем

    gameSwipesActive = false;

    window.removeEventListener('touchstart', preventDefault);
    window.removeEventListener('touchmove', preventDefault);
    window.removeEventListener('touchend', preventDefault);
    window.removeEventListener('touchcancel', preventDefault);
    console.log('Swipe prevention disabled');
}

function isGameScreenVisible() {
    return me.state.isCurrent(me.state.PLAY);
}

function onload(exitGameFunction) {
    if (loaded) return;

    loaded = true;

    const width = window.innerWidth;
    const height = window.innerHeight;

    if (!me.video.init(width, height, { 
        parent: "screen", 
        scaleMethod: 'fit',
        renderer: me.WebGLRenderer,
        antiAlias: true,
        subPixel: false 
    })) {
        alert("Ваш браузер не поддерживает HTML5 canvas.");
        return;
    }

    me.audio.init("wav", { pool: 5 });

    me.loader.preload(resources, function () {
        me.pool.register("player", PlayerEntity);
        me.pool.register("platform", PlatformEntity);

        me.state.set(me.state.MENU, new me.Stage());
        me.state.set(me.state.PLAY, new PlayScreen(exitGameFunction));

        me.state.change(me.state.MENU); // Изначально стартуем с меню

        game.gameController.start();

        initWorker();
        initMusicWorker();

        me.state.onResume = function() {
            if (isGameScreenVisible()) {
                enableSwipePrevention();
            } else {
                disableSwipePrevention();
            }
        };

        me.state.onStop = disableSwipePrevention;
        me.state.onPause = disableSwipePrevention;
    });
}

function initWorker() {
    if (window.Worker) {
        const worker = new Worker(new URL('./worker.js', import.meta.url));

        worker.postMessage({
            command: 'generatePlatforms',
            data: {
                numPlatforms: 10,
                width: window.innerWidth,
                height: window.innerHeight,
                playerPosition: { x: 100, y: 200 },
                gravity: 0.6,
                levelDifficulty: 'medium',
                platformTypes: ["p1", "p11"]
            }
        });

        worker.onmessage = function(event) {
            const { command, platforms } = event.data;
            if (command === 'platformsGenerated') {
                platforms.forEach(platform => {
                    try {
                        const platformEntity = me.pool.pull("platform", platform.x, platform.y, platform.variant);
                        platformEntity.z = 1;
                    } catch (error) {
                        console.error(`Ошибка при создании платформы: ${error.message}`);
                    }
                });
            }
        };

        worker.onerror = function(error) {
            console.error(`Ошибка в Web Worker: ${error.message}`);
        };

        worker.postMessage({ command: 'terminate' });
    } else {
        console.error("Web Workers не поддерживаются в этом браузере.");
    }
}

function initMusicWorker() {
    if (window.Worker) {
        musicWorker = new Worker(new URL('./musicWorker.js', import.meta.url));
    } else {
        console.error("Web Workers не поддерживаются в этом браузере.");
    }
}

function startMusic() {
    if (musicWorker) {
        musicWorker.postMessage({ command: 'playMusic', musicFile: 'path/to/music/file.mp3' });
    }
}

function stopMusic() {
    if (musicWorker) {
        musicWorker.postMessage({ command: 'stopMusic' });
    }
}

export default onload;
