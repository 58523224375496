// websocketClient.js

const WebSocketClient = (() => {
  let socket = null;
  let onMessageCallback = null;

  const init = (url) => {
    if (!socket) {
      socket = new WebSocket(url);

      socket.onopen = () => {
        console.log("WebSocket connection opened");
      };

      socket.onmessage = (event) => {
        console.log("Message received: ", event.data);
        if (onMessageCallback) {
          onMessageCallback(event.data);
        }
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed");
        socket = null;
      };

      socket.onerror = (error) => {
        console.error("WebSocket error: ", error);
      };
    }
  };

  const sendMessage = (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(message);
    } else {
      console.error("WebSocket is not connected.");
    }
  };

  const setOnMessageCallback = (callback) => {
    onMessageCallback = callback;
  };

  const close = () => {
    if (socket) {
      socket.close();
      socket = null;
    }
  };

  return {
    init,
    sendMessage,
    setOnMessageCallback,
    close
  };
})();

export default WebSocketClient;
