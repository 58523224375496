import * as me from 'melonjs'
import * as definitions from './ObjectPlacingDefinitions';
import PlatformEntity from '../Entities/Level/Platform';
import CarrotEntity from '../Entities/Level/Collectable/Carrot';
import PlayerEntity from '../Entities/Player';
import GenerationUnits from './GenerationUnits';
import LevelGenerator from './LevelGenerator';
import { GenerationConfig } from '../../components/configs/GeneratorConfigClasses';


class ObjectPlacer {

    constructor() {
        this.collection = new ProdObjectCollection();
    }

    placeRandomlyToScreenWidth(index, y, variant) {
        this.placeRelativelyToScreenWidth(index, Math.random(), y, variant);
    }
    placeRelativelyToScreenWidth(index, xRelative, y, variant) {
        this.placeAt(index, this.getAbsoluteXByRelative(xRelative), y, variant);
    }
    placeAt(index, x, y, variant) {
        this.collection.placeAt(index, x, y, variant);
    }

    getAbsoluteXByRelative(relative) {
        var w = this.getWidth();
        var xStart = w * GenerationConfig.instance.leftLimitX;
        var xEnd = w * GenerationConfig.instance.rightLimitX;
        
        var freeWidth = xEnd - xStart;
        
        return relative * freeWidth + xStart;
    }
    getWidth() {
        return me.game.viewport.width;
    }
}

class ProdObjectCollection extends definitions.ObjectCollection {
    constructor() {
        super();

        this.add(new PlayerPlacer());
        this.add(new CarrotPlacer());
        this.add(new PlatformPlacer());
    }
}

class PlayerPlacer extends definitions.ObjectDefinition {
    constructor() {
        super();

        this.index = "player";
        this.entity = PlayerEntity;
        this.player = undefined;
    }

    create(x, y) {
        if (this.player == undefined) {
            this.player = super.create(x, y);
        }
        return this.player;
    }

    correctPosition(instance) {
        instance.pos.x -= instance.width/2;
        instance.pos.y -= instance.height;
    }
}
class CarrotPlacer extends definitions.ObjectDefinition {
    constructor() {
        super();

        this.index = "carrot";
        this.entity = CarrotEntity;
    }
    correctPosition(instance) {
        instance.pos.x -= instance.width/2;
    } 
}
class PlatformPlacer extends definitions.ObjectDefinition {
    constructor() {
        super();
        
        this.index = "platform";
        this.entity = PlatformEntity;
    }

    create(x, y, platformType=GenerationConfig.instance.defaultPlatform) {
        var instance = super.create(x, y, platformType);

        return instance;
    }

    correctPosition(instance) {
        instance.pos.x -= instance.width/2;
        instance.pos.y += instance.height/2;
        
        instance.register();
    }
}





export default ObjectPlacer;