import * as me from 'melonjs';
import game from '../../game.js';
import EntityScaler from '../Tools/EntityScaler.js';
import config from "../../../components/configs/entityParameters.json"

const DIR = {
    LEFT: -1,
    RIGHT: 1
}

/**
 * a virtual joypad
 */
class Joypad extends me.UISpriteElement {
    /**
     * constructor
     */
    constructor(x, y) {
        super(x, y, {
            // background "fix" part of the joypad
            image : "joystick0",
            anchorPoint : new me.Vector2d(0, 0)
        });

        // mobile part of the joypad
        this.pad = new me.Sprite(x, y, {
            image : "joystick1",
            anchorPoint : new me.Vector2d(0, 0)
        });

        // default relative position from the back of the joypad
        this.relative = new me.Vector2d(
            this.width / 2 - this.pad.width / 2,
            this.height / 2 - this.pad.height /2
        );

        // offset by which the joypad move when pressed/moved
        this.joypad_offset = new me.Vector2d();

        // default opacity
        this.setOpacity(0.25);

        this.active = false;

        // cursors status
        // TODO make it configurable
        this.cursors = {
            up: false,
            down: false,
            left: false,
            right: false
        };
        var w = this.width;
        var h = this.height;
        EntityScaler.scaleToFitWidth(this, config.joystick.width);
        this.width = w;
        this.height = h;

        // register on the pointermove event
        me.input.registerPointerEvent('pointermove', this, this.pointerMove.bind(this));
        //me.input.registerPointerEvent('pointerup', me.input.pointerEventTarget, this.pointerUp.bind(this));

    }

    onDestroyEvent() {
        // release register event event
        me.input.releasePointerEvent("pointermove", this);
    }

    update(dt) {
        super.update(dt);

        if (game.input.check()) {
            this.pointerUp();
        }
    }

    pointerMove(event) {
        if (this.active) {
            var x = event.gameScreenX + (event.width / 2);
            var y = event.gameScreenY + (event.height / 2);

            if (this.getBounds().contains(x, y)) {
                this.checkDirection.call(this, x, y);
            }
        }
    }
    pointerUp() {
        this.active = false;
        
        this.setSideState(DIR.LEFT, false);
        this.setSideState(DIR.RIGHT, false);

        this.joypad_offset.set(0, 0);
        this.setOpacity(0.25);
        return false;
    }
    
    onClick(event) {
        this.active = true;
        var x = event.gameScreenX + (event.width / 2);
        var y = event.gameScreenY + (event.height / 2);
        this.setOpacity(0.50);
        this.checkDirection.call(this, x, y);
        return false;
    }


    // update the cursors value and trigger key event
    checkDirection(x, y) {
        this.updateButtonPos(x);

        if (this.mouseRelativeX(x) < this.getBounds().width * (0.5 - config.joystick.deadZone)) {
            this.setSideState(DIR.LEFT, true);
        } else {
            this.setSideState(DIR.LEFT, false);
        }
        if (this.mouseRelativeX(x) > this.getBounds().width * (0.5 + config.joystick.deadZone)) {
            this.setSideState(DIR.RIGHT, true);
        } else {
            this.setSideState(DIR.RIGHT, false);
        }
    }
    mouseRelativeX(x) {
        return x - this.pos.x;
    }
    

    draw(renderer) {
        super.draw(renderer);
        this.pad.pos.setV(this.pos).add(this.relative).add(this.joypad_offset);
        this.pad.draw(renderer);
    }

    updateButtonPos(mousePos) {
        this.joypad_offset.x = ((mousePos - this.pos.x) - this.getBounds().width / 2) / 4;
    }

    setSideState(side, state) {
        if (state) {
            if (!this.getSideState(side))
                me.input.triggerKeyEvent(this.getKeyForSide(side), true);

            this.setSideState(this.getNegativeSide(side), false);

            this.setCursorValueForSide(side, true);
        } else {
            if (this.getSideState(side))
                me.input.triggerKeyEvent(this.getKeyForSide(side), false);

            this.setCursorValueForSide(side, false);
        }
    }
    setCursorValueForSide(side, value) {
        if (side > 0) {
            this.cursors.right = value;
        } else {
            this.cursors.left = value;
        }
    }
    getSideState(side) {
        return side > 0 ? this.cursors.right : this.cursors.left;
    }
    getKeyForSide(side) {
        return side > 0 ? me.input.KEY.RIGHT : me.input.KEY.LEFT;
    }
    getNegativeSide(side) {
        return -side;
    }
};

/**
 * a very simple virtual joypad and buttons, that triggers
 * corresponding key events
 */
class VirtualJoypad extends me.Container {

    constructor() {

        // call the constructor
        super();

        // persistent across level change
        this.isPersistent = true;

        // Use screen coordinates
        this.floating = true;

        // make sure our object is always draw first
        this.z = 1000;

        // give a name
        this.name = "VirtualJoypad";

        // instance of the virtual joypad
        this.joypad = new Joypad(
            50,
            50
        );

        this.joypad.pos.x = me.game.viewport.width/2 - this.joypad.getBounds().width/2;
        this.joypad.pos.y = me.game.viewport.height - this.joypad.getBounds().height;


        this.addChild(this.joypad);
    }

    update(dt) {
        this.depth = 1000;
        
        super.update(dt);
    }
};


export default VirtualJoypad;