import classes from './Home.module.css'
import bg from '../../../assets/images/main-bg.jpg'
import Menu from '../../ui/Menu/Menu';
import CarrotBalance from '../../ui/CarrotBalance/CarrotBalance';
import { ReactComponent as Rabbit } from '../../../assets/images/rabbit.svg';
import { ReactComponent as SadRabbit } from '../../../assets/images/sadRabbit.svg';
import { ReactComponent as DepRabbit } from '../../../assets/images/depressedRabbit.svg';
import { ReactComponent as Sun } from '../../../assets/images/sun.svg';
import vintik from '../../../assets/images/vintik.png'
import showel from '../../../assets/images/showel.png'
import starveCarrot from '../../../assets/images/starveCarrot.svg'
import staminaIcon from '../../../assets/images/staminaIcon.png'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import Boost from '../../modals/Boost/Boost';
import Oops from '../../modals/Oops/Oops';
import ComingSoon from '../../modals/ComingSoon/ComingSoon';
import timeToFeed from '../../../assets/images/timetofeed.png'
import { claim } from '../../../api/boosterApi';
import { updateBalance, updateMiningSecodns } from '../../../store/userSlice';
import { setClose } from '../../../store/oopsSlice';
import { feed } from '../../../api/feedRabbitApi';
import Onboarding from '../../modals/Onboarding/Onboarding';

const Home = ({ startGame, started, setActiveTab}) => {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token.token)
  const dispatch = useDispatch()
  const [isBoostOpen, setIsBoostOpen] = useState(false)
  const [isSoonOpen, setIsSoonOpen] = useState(false)
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(true)
  const oops = useSelector((state) => state.oops.isOpen);
  const oopsText = useSelector((state) => state.oops.content)
  const step = useSelector((state) => state.step.step);

  // Функции для изменения кролика и цвета
  // Мемоизация feedRabbit
const feedRabbit = useMemo(() => {
  const percentage = ((21600 - user.time_since_last_feed) / 21600) * 100;

  if (percentage <= 25) {
    return <DepRabbit className={classes.Rabbit} />;
  } else if (percentage <= 50) {
    return <SadRabbit className={classes.Rabbit} />;
  } else {
    return <Rabbit className={classes.Rabbit} />;
  }
}, [user.time_since_last_feed]);

// Мемоизация feedColor
const feedColor = useMemo(() => {
  const percentage = ((21600 - user.time_since_last_feed) / 21600) * 100;

  if (percentage <= 25) {
    return (
      <div className={classes.starveMeter} style={{ width: `${percentage}%`, backgroundColor: '#E53A1B', boxShadow: '0px 2px 6px 0px #F63 inset' }}></div>
    );
  } else if (percentage <= 50) {
    return (
      <div className={classes.starveMeter} style={{ width: `${percentage}%`, backgroundColor: '#FFBC00', boxShadow: '0px 2px 6px 0px #F63 inset' }}></div>
    );
  } else {
    return (
      <div className={classes.starveMeter} style={{ width: `${percentage}%`, backgroundColor: '#76B733', boxShadow: '0px 2px 6px 0px #9DC97E inset' }}></div>
    );
  }
}, [user.time_since_last_feed]);

  // Следим за изменениями в user.time_since_last_feed
  
  function secondsToHoursMinutes(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    
    return `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m`;
  }

  function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(secs).padStart(2, '0');
    
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

function secondsToHMS2(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(secs).padStart(2, '0');
  
  return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
}

  const claimHandler = async () => { 
    const response = await claim(token);
    if (response.status === 'success') {
      dispatch(updateBalance({ balance: response.new_balance }));
      dispatch(updateMiningSecodns({ mining_claim_seconds: response.mining_claim_seconds }));
    }
  };

  return (
    <div className={classes.Home}>
      {isBoostOpen && <Boost isOpen={isBoostOpen} onClose={() => setIsBoostOpen(false)} soonOpen={() => setIsSoonOpen(true)}/>}
      {oops && <Oops isOpen={oops} onClose={() => dispatch(setClose())} text={oopsText}/>}
      {isSoonOpen && <ComingSoon isOpen={isSoonOpen} onClose={() => setIsSoonOpen(false)}/>}
      {user.is_new_user && <Onboarding isOpen={isOnboardingOpen} onClose={() => setIsOnboardingOpen(false)}/>}
      <img src={bg} alt='bg' className={classes.bg}/>
      
      <div className={classes.info}>
        <div className={classes.carrotsBalance}>
          <CarrotBalance value={user.regular_carrots} type={'orange'}/>
          <CarrotBalance value={user.golden_carrots} type={'gold'}/>
          <CarrotBalance value={user.diamond_carrots} type={'diamond'}/>
        </div>
        <div className={classes.hareBalance} style={{zIndex: step === 5 && '10000'}}>
          <p className={classes.balanceTitle}>HARE BALANCE</p>
          <p className={classes.balanceValue}>{Math.floor(user.balance)}<span className={classes.fontBack}>{Math.floor(user.balance)}</span></p>
        </div>

        <div className={classes.harvestContainer}>
            <div className={classes.harvestSubContainer}>
              <div className={classes.harvestInfo}>
                <p className={classes.harvestText}>AVAILABLE TO CLAIM: <span className={classes.harvestStat}>{Math.round(10 * user.mining_level * (user.mining_claim_seconds / 3600))}</span></p>
                <div className={classes.harvestMeter}>
                  <div className={classes.harvestTime}>{user.mining_level ? secondsToHMS2(user.mining_claim_seconds) : '00h 00m 00s'}</div>
                  <div className={classes.harvestPercent} style={user.mining_level ? {width: `${user.mining_claim_seconds / 28800 * 100}%`} : {width : '0%'}}></div>
                </div>
                <p className={classes.harvestText}>AVAILABLE TO CLAIM PER HOUR: <span className={classes.harvestStat}>{10 * user.mining_level}</span></p>
              </div>
              <div className={classes.harvestButton} onClick={claimHandler} style={{opacity:( user.mining_claim_seconds >= 3600 && user.mining_level > 0) ? '100%' : '50%'}}>
                <img src={showel} alt='showel' className={classes.showel}/>
                HARVEST
              </div>
          </div>
        </div>
      </div>
      <div className={classes.rabbitContainer}>
        <div className={classes.char}>
          {feedRabbit}
          <Sun className={classes.sun}/>
        </div>
      </div>
      <div className={classes.feedStaminaContainer}>
        <div className={classes.feedContainer} style={{zIndex: step === 4 && '10000'}}>
          <div className={classes.timeToFeed}><img src={timeToFeed} alt='time' className={classes.timeIcon}/>Time to feed: <span className={classes.feedTime}>{secondsToHMS(21600 - user.time_since_last_feed)}</span></div>
            <div className={classes.starveContainer}>
              <div className={classes.feedbg}>
                {feedColor}
              </div>
              <div className={classes.decor}>
                <img src={vintik} alt='screw' className={classes.vintik}/>
              </div>
              <div className={classes.starveCarrot}>
                <img src={starveCarrot} alt='carrot' className={classes.starveCarrotImg}/>
              </div>
            </div>
        </div>
          <div className={classes.staminaContainer} style={{zIndex: step === 6 && '10000'}}>
            <div className={classes.stamina} style={{width: `${user.energy}%`}}></div>
            <div className={classes.icon}>
              <img src={staminaIcon} alt='icon' className={classes.staminaIcon}/>
            </div>
          </div>
        </div>

      <Menu startGame={startGame} started={started} setActiveTab={setActiveTab} boostOpen={() => setIsBoostOpen(true)} />
    </div>
  )
}

export default Home;