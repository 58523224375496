import { useSelector } from 'react-redux';
import CarrotBalance from '../CarrotBalance/CarrotBalance'
import classes from './GameHeader.module.css'

const GameHeader = () => {
  const user = useSelector((state) => state.user);

  return (
    <div className={classes.GameHeader}>
      <CarrotBalance value={user.regular_carrots} type={'orange'}/>
      <CarrotBalance value={user.golden_carrots} type={'gold'}/>
      <CarrotBalance value={user.diamond_carrots} type={'diamond'}/>
    </div>
  )
}

export default GameHeader