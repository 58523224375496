import * as me from 'melonjs'
import EntityScaler from '../Tools/EntityScaler';
import config from '../../../components/configs/entityParameters.json';
import Camera from '../Camera';
import JumpAnimation from '../Tools/JumpAnimation';

class DeadPlayer extends me.Entity {
    constructor(player) {

        const imageName = "PlayerDie";
        var image = me.loader.getImage(imageName);

        super(player.pos.x, player.pos.y, {
            image: imageName,
            width: image.width,
            height: image.height
        });

        this.z = 1000;

        EntityScaler.scaleToFitWidth(this, config.player.width);

        this.pos.y = me.game.viewport.getBounds().bottom + this.height*0.5;

        this.anchorPoint.setV(player.anchorPoint);

        this.body.gravityScale = player.body.gravityScale;

        this.alwaysUpdate = true;
        this.cameraPosOriginal = Camera.getY();
        this.cameraPosLimit = this.cameraPosOriginal + me.game.viewport.height*2;
        
        this.animation = new JumpAnimation(this);

        this.animation.start(player.simpleJumpHeight * 1.5);
    }

    update(dt) {
        if (!this.animation.isActive()) {
            var nextPos = this.pos.y + this.height - me.game.viewport.height/2;
            var maxPos = Math.max(nextPos, Camera.getTargetY())
            Camera.moveToY(Math.min(this.cameraPosLimit, maxPos));
        }
        this.animation.update(dt);

        super.update(dt);
    }
}

export default DeadPlayer;