import * as me from 'melonjs';
import CollectableEntity from './CollectableEntity';
import EntityScaler from '../../Tools/EntityScaler';
import entityParameters from "../../../../components/configs/entityParameters.json";
import WebSocketClient from '../../../../api/wsApi';

// Встраиваем функцию calculateCarrots непосредственно в этот файл
function calculateCarrots(baseAmount, boosterMeal) {
    const baseCarrots = 10;
    const additionalCarrots = Math.min(boosterMeal, 7);
    const totalCarrots = baseAmount * (baseCarrots + additionalCarrots);
    return totalCarrots;
}

class CarrotEntity extends CollectableEntity {
    static lastCollisionTime = 0; // Статическая переменная для отслеживания времени последнего сбора

    constructor(x, y, settings) {
        var image = me.loader.getImage("carrot");
        super(x, y, {
            image: "carrot",
            width: image.width,
            height: image.height
        });

        this.type = "carrot"; // изменяем тип на "carrot"
        this.price = 1;

        this.body.collisionType = me.collision.types.COLLECTABLE_OBJECT;
        this.anchorPoint.set(0.5, 0.5);
        this.body.setStatic(true);

        EntityScaler.scaleToFitWidth(this, entityParameters.carrot.width);
    }

    onCollision(response, other) {
        if (other.body.collisionType === me.collision.types.PLAYER_OBJECT) {
            const currentTime = new Date().getTime();

            // Проверяем, прошло ли 0.5 секунды (500 мс) с момента последнего сбора
            if (currentTime - CarrotEntity.lastCollisionTime < 500) {
                return true; 
            }

            // Обновляем время последнего сбора
            CarrotEntity.lastCollisionTime = currentTime;

            WebSocketClient.sendMessage('1');

            // Удаляем морковку из игры после сбора
            me.game.world.removeChild(this);

            return false; // Прерываем дальнейшую обработку коллизий
        }

        return true;
    }
}

export default CarrotEntity;
