import classes from './Oops.module.css'
import bg from '../../../assets/images/oopsbg.svg'
import carrot from '../../../assets/images/orangeValue.svg'
import close from '../../../assets/images/closeButton.png'

const Oops = ({ isOpen, onClose, text }) => {
  if (!isOpen) return null;

  return (
    <div className={classes.overlay} onClick={onClose}>
      <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
        <div className={classes.cross} onClick={() => onClose()}>
          <img src={close} alt='close' className={classes.close}/>
        </div>
        <img src={'/assets/oopsbg.svg'} alt='bg' className={classes.bg}/>
        <div className={classes.content}>
          <p className={classes.text}>Oooops!<span className={classes.fontBack}>Oooops!</span></p>
          <p className={classes.message}>{text}</p>
          <div className={classes.button} onClick={() => onClose()}>
            Top-Up
          </div>
        </div>
      </div>
    </div>
  );
};

export default Oops;