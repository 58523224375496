import * as me from 'melonjs';

class ObjectCollection {
    /** @type {ObjectDefinition[]} */
    definitions = [];

    add(definition) {
        this.definitions.push(definition);
    }

    placeAt(index, x, y, variant=undefined) {
        this.getDefinition(index).placeAt(x, y, variant);
    }

    getCount(index) {
        return this.getDefinition(index).getCount();
    }

    getDefinition(index) {
        for(var definition of this.definitions) {
            if (definition.index == index) {
                return definition;
            }
        }

        throw new Error('unknown index ' + index);
    }
}

class ObjectDefinition {
    constructor(entity = undefined, index = undefined) {
        this.entity = entity;
        this.index = index;
        this.count = 0;
    }

    placeAt(x, y, variant) {
        var instance = this.create(x, y, variant);
        this.correctPosition(instance);
        return instance;
    }

    create(x, y, variant) {
        var instance = new this.entity(x, y, variant);
        me.game.world.addChild(instance);

        this.count++;

        return instance;
    }

    correctPosition(instance) {
        instance.x -= instance.width/2;
        instance.y -= instance.height/2;
    }

    getCount() {
        return this.count;
    }
}

export {
    ObjectCollection,
    ObjectDefinition
}