import * as me from 'melonjs'
import game from '../../../game';
import EntityScaler from '../../Tools/EntityScaler';
class ScoreText extends me.Text {
    constructor(x, y) {
        // call the constructor
        super(x, y , {
            width: 200,
            height: 40,
            font: "Arial",
            size: 32
        });
        
        this.alwaysUpdate = true;
    }

    update(dt) {
        var score = game.data.score;
        if (score == undefined) {
            score = 0;
        }
        this.setText(score);
    }
}
class Back extends me.Container {
    constructor() {
        super();

        this.floating = true;

        this.alwaysUpdate = true;

        var imageBack = me.loader.getImage("Back");
        var imageTrees = me.loader.getImage("back_center");
        var side = me.loader.getImage("side");
        var Grass = me.loader.getImage("Grass");

        this.Back = new me.Sprite(0, 0, {
            image: "Back",
            width: imageBack.width,
            height: imageBack.height,
            anchorPoint: new me.Vector2d(0, 0),
        });
        this.trees = new me.Sprite(0, 0, {
            image: "back_center",
            width: imageTrees.width,
            height: imageTrees.height,
            anchorPoint: new me.Vector2d(0, 0),
        });
        this.side1 = new me.Sprite(0, 0, {
            image: "side",
            width: side.width,
            height: side.height,
            anchorPoint: new me.Vector2d(0, 0),
        });
        this.side2 = new me.Sprite(0, 0, {
            image: "side",
            width: side.width,
            height: side.height,
            anchorPoint: new me.Vector2d(0, 0),
        });
        this.grass = new me.Sprite(0, 0, {
            image: "Grass",
            width: Grass.width,
            height: Grass.height,
            anchorPoint: new me.Vector2d(0, 0),
        });

        EntityScaler.scaleToFitWidth(this.Back, 100);
        EntityScaler.scaleToFit(this.grass, 100, 100, 'max');
        EntityScaler.scaleToFitWidth(this.trees, 80);
        EntityScaler.scaleToFit(this.side1, 100, 100, 'h');
        EntityScaler.scaleToFit(this.side2, 100, 100, 'h');

        this.addChild(this.Back);
        this.addChild(this.trees);
        this.addChild(this.side1);
        this.addChild(this.side2);
        this.addChild(this.grass);

        this.trees.pos.x = me.game.viewport.width/2 - this.trees.width/2;
        this.trees.pos.y = me.game.viewport.height - this.trees.height - this.grass.height/2;

        this.grass.pos.x = me.game.viewport.width/2 - this.grass.width/2;
        this.grass.pos.y = me.game.viewport.height - this.grass.height;

        this.side2.pos.x = me.game.viewport.width - this.side2.width;
        this.side1.flipX(1);
    }

    update(dt) {
        this.depth = -1000;
        super.update(dt)
    }
}

class Back2 extends me.Container {
    constructor() {
        super();

        this.floating = true;
        this.z = Infinity;

        this.score = new ScoreText(100, 50);
        this.addChild(this.score);
    }
}

export default Back;